// import './App.css';
import { z } from "zod";
import { useEffect, useState } from 'react';
// import LineChart from './lineChart';
import { ApexOptions } from 'apexcharts';
import { useForm } from "react-hook-form";
import ReactApexChart from 'react-apexcharts';
import * as api from '../../../../services/api';
import { zodResolver } from "@hookform/resolvers/zod";
import CustomSelectWithCheckbox from "../MonthYearSelect";
import React, { createContext, useContext } from 'react';

const categories: Array<string> = [
    'name', 'description', '2024', '2023', '2022', '2021', '2020',
    '2019', '2018', '2017', '2016', '2015',
    '2014', '2013', '2012', '2011', '2010',
    '2009', '2008', '2007', '2006', '2005',
    '2004', '2003', '2002', '2001', '2000'
];

// Criando o esquema zod com campo fixo "nome" e categorias dinâmicas
const schemaForm0 = z.object({
    product: z.string().min(1, 'Selecione um Produto.'),
    manuf: z.string().min(1, "Selecione um Fabricante."),
    model: z.string().min(1, "Selecione um Modelo."),
});

// Criando o esquema zod com campo fixo "nome" e categorias dinâmicas
const schemaForm1 = z.object({
    FC: z.record(
        z.string().regex(/^\d{4}$/), // Valida que as chaves são anos (formato YYYY)
        z.number().positive()        // Valores devem ser números positivos
    ),
    FN: z.string().min(1, "Adicione Fator de Nivelação."),
    fa_min: z.string().min(1, "Adicione o Valor Mínimo do Range ."),
    fa_max: z.string().min(1, "Adicione o Valor Máximo do Range ."),
});

const schemaForm2 = z.object(
    categories.reduce((acc, field) => {
        acc[field] = z.string(); // Define o validador para cada categoria como string
        return acc;
    }, {} as Record<string, z.ZodString>) // Tipagem correta para string
);

type TypeSchemaForm0 = z.infer<typeof schemaForm0>;
type TypeSchemaForm1 = z.infer<typeof schemaForm1>;
type TypeSchemaForm2 = z.infer<typeof schemaForm2>;

type monthYearRef = {
    reference_month: string,
    reference_year: string,
}

type Manufs = {
    mounter_code: string,
    mounter: string
}

type Models = {
    mounter: string,
    model: string,
    model_code: string,
    description: string
}

// Função auxiliar para carregar do localStorage
const loadState = (key: string, defaultValue: any) => {
    try {
        const saved = localStorage.getItem(key);
        if (saved !== null) {
            return JSON.parse(saved);
        } else {
            return defaultValue;
        }
    } catch (error) {
        console.error(`Erro ao carregar ${key} do localStorage:`, error);
        return defaultValue;
    }
};

function Graphic() {

    const [manualLineAble, setManualLineAble] = useState<boolean>(false);
    const [childValue, setChildValue] = useState<monthYearRef[]>([]);
    const [monthYearOptions, setMonthYearOptions] = useState<any>([]);
    const [is_saved, setIs_saved] = useState<boolean>(false);
    const [is_published, setIs_published] = useState<boolean>(false);
    const [mapValue, setMapValue] = useState<any>([]);
    const [manufs, setManufs] = useState<Manufs[]>();
    const [models, setModels] = useState<Models[]>();
    const [modelCode, setModelCode] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [r2, setR2] = useState<string | null>(loadState('r2', null));

    const {
        register: registerForm0,
        getValues: getValues0,
        handleSubmit: handleSubmitForm0,
        formState: { errors: errors0 },
        reset: resetForm0,
    } = useForm<FormInputTypes0>({
        mode: "all",
        resolver: zodResolver(schemaForm0),
        defaultValues: {
            manuf: undefined,
            product: undefined,
            model: undefined
        }
    });

    const { setValue: setValue1, getValues: getValues1, register: registerForm1, handleSubmit: handleSubmitForm1, reset: resetForm1, } = useForm<TypeSchemaForm1>({
        mode: "all",
        resolver: zodResolver(schemaForm1),
    });

    const { setValue: setValue2, getValues: getValues2, register: registerForm2, handleSubmit: handleSubmitForm2, reset: resetForm2, } = useForm<TypeSchemaForm2>({
        mode: "all",
        resolver: zodResolver(schemaForm2),
    });

    const getFromLocalStorage = () => {

        const saved = localStorage.getItem('mapValue');

        if (saved) {

            const data = JSON.parse(saved);

            if (data.length > 0) {

                const a = createYearArray(data);
                const b = createYearArrayPS(data);

                return [
                    {
                        name: `${localStorage.getItem("description")} - MÉDIA DE MERCADO`,
                        data: a
                    },
                    {
                        name: `${localStorage.getItem("description")} - PREÇO SOMOS`,
                        data: b
                    }
                ];

            } else {
                return [
                    {
                        name: "",
                        data: []
                    },
                    
                ];
            }

        } else {
            return [{
                name: "",
                data: []
            }]
        }

    }

    const [chartState, setChartState] = useState<{
        series: { name: string; data: number[] }[];
        options: ApexCharts.ApexOptions;
    }>({
        series: getFromLocalStorage(),
        options: {
            chart: {
                height: 350,
                type: "line", // Certifique-se de que é uma das opções válidas
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "straight",
                width: 2,
            },
            markers: {
                size: 2,
                strokeColors: "none",
                strokeWidth: 2,
                hover: {
                    size: 7,
                },
            },
            title: {
                text: "",
                align: "left",
            },
            grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"],
                    opacity: 0.2,
                },
            },
            xaxis: {
                categories: [
                    "2024", "2023", "2022", "2021", "2020",
                    "2019", "2018", "2017", "2016", "2015",
                    "2014", "2013", "2012", "2011", "2010",
                    "2009", "2008", "2007", "2006", "2005",
                    "2004", "2003", "2002", "2001", "2000",
                ],
                tickAmount: 24,
            },
        },
    });

    const setData = async (obj: TypeSchemaForm0) => {

        const model0 = obj.model;

        await api.get(`/table/crawler/calc/factors/semitrailer/${model0}/`).then((res: any) => {
            setValue1("fa_min", res.data.content.fa_min);
            setValue1("fa_max", res.data.content.fa_max);
            setValue1("FN", res.data.content.FN);
            setValue1("FC", res.data.content.FC);
        });

        let dataBody = {
            fa_min: getValues1("fa_min"),
            fa_max: getValues1("fa_max"),
            FN: getValues1("FN"),
            FC: getValues1("FC"),
            reference_dates: childValue
        };

        const resp: any = await api.post(`/table/crawler/calc/final/semitrailer/${model0}/`, dataBody);

        const uuid = resp.data.content.task_id;

        const respGet: any = await api.get(`/table/crawler/check_status/?task_id=${uuid}`);

        const finalCalc = respGet.data.content.result.calculation_results.results;
        const [firstKey, firstValue] = Object.entries(finalCalc)[0] as [string, Record<string, any>];

        setR2(Object.values(firstValue)[0].final_calc.R2);

        setMapValue(Object.values(firstValue));

        resetForm0();

    }

    const addLine = (data: TypeSchemaForm2) => {

        if (data.description === "" || data.name === "") {
            return
        }

        const sortedByValues = Object.entries(data).sort(([keyA], [keyB]) => keyB.localeCompare(keyA));

        const newDataArray: Array<number> = sortedByValues.slice(2).map(([key, value]) => {

            const numValue = Number(value);

            if (!isNaN(numValue)) {
                return Number(value);
            } else {
                return 0;
            }

        });

        setChartState((prevState) => ({
            ...prevState,
            series: [
                ...prevState.series,
                {
                    name: data.description ? data.description : data.name,
                    data: newDataArray,
                },
            ],
        }));

        resetForm1();

    };

    const filterInputSchema0 = z.object({
        product: z.string().min(1, 'Selecione um Produto.'),
        manuf: z.string().min(1, "Selecione um Fabricante."),
        model: z.string().min(1, "Selecione um Modelo."),
    });

    type FormInputTypes0 = z.infer<typeof filterInputSchema0>;

    const handleValueFromChild = (value: monthYearRef[]) => {
        setChildValue(value);
    };

    const getProd = async (prod: string) => {

        const resp: any = await api.get(`table/search/mounter/3/`);
        await getManuf(resp.data.content);
        return;
    }

    const getManuf = async (manufs: any) => {
        setManufs(manufs);
        return;
    }

    const getModel = async (manufCode: string) => {

        await api.get(`table/search/codes/3/${manufCode}/`).then((response: any) => {
            setModels(response.data.content);
        });
    }

    const getModelCode = async (modelCode: string) => {
        setModelCode(modelCode);
        await handleMonthYearOptions(modelCode);
    }

    const handleMonthYearOptions = async (modelCode: string) => {
        await api.get(`/table/crawler/calc/reference_dates/semitrailer/${modelCode}/`).then((res: any) => { setMonthYearOptions(res.data.content) });
    }

    const findDataByYear = (mapValue2: any, year: number) => {

        const index = mapValue2.findIndex((item: any) => item.year_model == year);
        const foundItem = mapValue2.find((item: any) => item.year_model == year);

        return { index, foundItem };
    };

    function createYearArray(newObj: any) {
        const startYear = 2000;
        const currentYear = new Date().getFullYear();

        // Quantidade de anos desde 2000 até o ano atual
        const arraySize = currentYear - startYear + 1;

        // Cria um array preenchido com zeros
        const yearArray = new Array(arraySize).fill(0);

        // Percorre cada objeto no array e preenche a posição correta com o preço
        newObj.forEach((obj: any) => {
            const yearPosition = obj.year_model % 100;

            // Verifica se a posição calculada está dentro do tamanho do array
            if (yearPosition >= 0 && yearPosition < arraySize) {
                yearArray[yearPosition] = obj.MM;
            }
        });

        return yearArray;
    }

    function createYearArrayPS(newObj: any) {
        const startYear = 2000;
        const currentYear = new Date().getFullYear();

        // Quantidade de anos desde 2000 até o ano atual
        const arraySize = currentYear - startYear + 1;

        // Cria um array preenchido com zeros
        const yearArray = new Array(arraySize).fill(0);

        // Percorre cada objeto no array e preenche a posição correta com o preço
        newObj.forEach((obj: any) => {
            const yearPosition = obj.year_model % 100;

            // Verifica se a posição calculada está dentro do tamanho do array
            if (yearPosition >= 0 && yearPosition < arraySize) {
                yearArray[yearPosition] = obj.final_calc.PS;
            }
        });

        return yearArray;
    }

    useEffect(() => {

        const a = createYearArray(mapValue);
        const allZeros = a.every(num => num === 0);

        if (description === "i" || name === "i" || allZeros) {
            return;
        }

        setChartState((prevState) => ({
            ...prevState,
            series: [
                ...prevState.series,
                {
                    name: description === "" ? name : description,
                    data: a,
                },
            ],
        }));

    }, [mapValue, setMapValue])

    return (
        <div className='flex justify-center items-center bg-white w-full h-max rounded-b-xl '>
            <div className='bg-white rounded-b-xl w-full min-h-[80vh] h-max flex flex-col sm:p-9  py-8 px-6 sm:px-12'>
                <h2 className='text-[24px] font-semibold text-gray-600'>GRÁFICO</h2>
                <div className="flex flex-col sm:flex-row mt-12 mb-4 w-full justify-between">
                    <form className='flex flex-col flex-wrap sm:flex-nowrap justify-center items-start w-full sm:w-[60%]' onSubmit={handleSubmitForm0(setData)}>

                        <div className="flex flex-col sm:flex-row w-full mr-4">
                            <label htmlFor="" className="flex flex-col w-full sm:w-[33%] text-gray-500 font-semibold">
                                Produto
                                <select id="" className="px-2 rounded-full sm:rounded-none sm:rounded-l-full w-full border-2 border-gray-200 text-[14px] font-semibold text-gray-500 h-10" {...registerForm0("product")} onChange={(e) => getProd(e.target.value)}>
                                    <option value="FABRICANTE" selected hidden>PRODUTO</option>
                                    <option value="semitrailer">SEMIRREBOQUE</option>
                                </select>
                            </label>
                            <label htmlFor="" className="flex flex-col w-full sm:w-[33%] text-gray-500 font-semibold">
                                Fabricante
                                <select id="" className="px-2 w-full border-2 rounded-full sm:rounded-none border-gray-200 text-[14px] font-semibold text-gray-500 h-10" {...registerForm0("manuf")} onChange={(e) => { getModel(e.target.value) }}>
                                    <option value="FABRICANTE" selected hidden>FABRICANTE</option>
                                    {manufs?.map((item: any) => {
                                        return <option value={item.mounter_code}>{item.mounter}</option>
                                    })}
                                </select>
                            </label>
                            <label htmlFor="" className="flex flex-col w-full sm:w-[33%] text-gray-500 font-semibold">
                                Modelo
                                <select id="" className="px-2 rounded-full sm:rounded-none sm:rounded-r-full w-full border-2 border-gray-200 -ml-1 text-[14px] font-semibold text-gray-500 h-10" {...registerForm0("model")} onChange={(e) => {
                                    getModelCode(e.target.value);
                                    const selectedItem = models?.find((item: any) => item.model_code === e.target.value);
                                    if (selectedItem) {
                                        setDescription(selectedItem.description);
                                    }
                                }}>
                                    <option value="MODELO" selected hidden>MODELO</option>
                                    {models?.map((item: any) => {
                                        return <option value={item.model_code} onClick={() => setDescription(item.description)}>{item.description}</option>
                                    })}
                                </select>
                            </label>
                        </div>
                        <div className="w-full sm:w-[100%] flex flex-col sm:flex-row justify-start items-start align-top gap-2 sm:pr-3 sm:mt-12">

                            <CustomSelectWithCheckbox onSelectionChange={handleValueFromChild} options={monthYearOptions} />

                            <div className="flex items-end w-full sm:w-[33%]"><button className="bg-[#6fbf8c] text-white h-11 rounded-full w-full py-4 px-10 flex justify-center items-center text-[14px] font-semibold mt-6" type="submit" >ADICIONAR</button></div>
                        </div>

                    </form>
                    <button className="bg-[#00AEEF] text-white h-10 rounded-full w-full sm:w-max text-nowrap py-4 px-6 flex justify-center items-center text-[14px] font-semibold mt-5" onClick={() => setManualLineAble(!manualLineAble)}>ADICIONAR MANUALMENTE</button>
                </div>
                {
                    manualLineAble
                        ?
                        <form className='shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)] min-h-[30vh] h-max rounded-md p-4 mt-4' action="" onSubmit={handleSubmitForm2(addLine)}>
                            <p className='text-[12px] font-semibold text-gray-500'>ADICIONANDO LINHA MANUALMENTE</p>
                            <div className='divTable'>
                                <table className="min-w-full rounded-lg mt-6 tableFilter">
                                    <thead>
                                        <tr>
                                            <th className="bg-gray-300 text-gray-400 min-w-[240px]">NOME</th>
                                            <th className="bg-gray-300 text-gray-400 min-w-[240px]">DESCRIÇÃO</th>
                                            {chartState.options.xaxis?.categories?.map((el: any) => <th className="bg-gray-300 text-gray-400 min-w-[120px]">{el}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {categories.map((el) => { return <td><input type="text" key={el} className="h-full w-full p-0 border-none outline-none" {...registerForm2(el)} /></td> })}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-10 w-full text-right flex items-end justify-end'>
                                <button className="bg-[#6fbf8c] text-white h-10 rounded-full w-full sm:w-max py-4 px-6  mx-2 flex justify-center items-center text-[14px] font-semibold mt-5" type="submit">SALVAR</button>
                            </div>
                        </form>
                        :
                        <></>
                }
                <div className='mt-9 overflow-hidden'>
                    {/* <LineChart name={objToSend.name} values={objToSend.values} /> */}
                    <div>
                        <div id="chart" className='min-w-[800px]'>
                            <ReactApexChart
                                options={chartState.options}
                                series={chartState.series}
                                type="line"
                                height={350}
                            />
                        </div>
                        <div id="html-dist"></div>
                        <p className='font-bold z-0 text-red-500 text-center'>R²: {r2}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Graphic;
