import { styled } from '@mui/system';
import colors from "../../styles/colors";
import { useAuth } from "../../hooks/useAuth";
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useWindowSize from "../../hooks/useWindowSize";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormControl, Select, MenuItem, AppBar, Container, Toolbar, Button, Box, IconButton, Avatar, Tooltip, Menu, Typography, Modal } from '@mui/material';

// Estilizando a seta
const CustomArrow = styled(ArrowDropDownIcon)({
  color: 'blue', // Altere para a cor que deseja
});

type MenuProps = {
  name: string;
  path?: string;
  paths?: MenuProps[];
};

type OptionKeys = 'pricing' | 'search-engines' | 'new-table';

export default function NavBar(): JSX.Element {

  const translatePage = (nameParams: string | null): string => {

    const name = nameParams?.replace('/', '');

    if (!name) {
      return 'GERENCIAMENTO';
    }

    const options: Record<OptionKeys, string> = {
      'pricing': 'PRECIFICADOR',
      'search-engines': 'BUSCASDORES',
      'new-table': 'PLANILHAS'
    }

    return options[name as OptionKeys] || 'GERENCIAMENTO';

  }

  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { user, Logout, content, getUserData } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [activeButton, setActiveButton] = useState<string | null>(localStorage.getItem('navbarState'));
  const [selectedOption, setSelectedOption] = useState<string>(localStorage.getItem('navbarState') === null ? 'GERENCIAMENTO' : translatePage(localStorage?.getItem('navbarState')));
  const [isGerenciamentoOpen, setIsGerenciamentoOpen] = useState(false);
  const [somosCode, setSomosCode] = useState<number | string>('all');
  const [pagePaths, setPagePaths] = useState<MenuProps[] | undefined>([]);

  useEffect(() => {
    getUserData(user?.id)
  }, [getUserData, user?.id]);

  const handleButtonClick = (path: string) => {
    setActiveButton(path);
    localStorage.setItem("navbarState", path);
    navigate(path);
    handleCloseNavMenu();
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pageChange = (event: any) => {
    setSelectedOption(event.target.value);
  }

  const renderButtonOrSelect = (page: MenuProps) => {
    if (page.name === 'Gerenciamento') {
      //setPagePaths(page.paths);
      return (
        <FormControl sx={{ width: 'max-content', height:  width >= 990 ? '4.5rem' : '', padding: '0', margin: '0' }}>
          <Select
            value={'GERENCIAMENTO'}
            onChange={(e) => pageChange(e)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            IconComponent={CustomArrow}
            sx={{
              '& .MuiSelect-select': {
                paddingTop: width >= 990 ? '' : '0 !important',
                paddingBottom: width >= 990 ? '' : '0 !important',
                border:  width >= 990 ? '' : 'none !important',
              },
              color: width >= 990 ? 'white' : 'black',
              background: width >= 990 ? '#252028' : 'transparent',
              borderRadius:  width >= 990 ? 1 : 0,
              border:  width >= 990 ? '' : 'none !important',
              fontSize: '1rem',
              width: 'max-content',
              margin: '0',
              padding: width >= 990 ? '0': '0 !important',
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: width >= 990 ? '#252028' : 'white',
                  color: width >= 990 ? 'white' : 'black',
                  fontSize: '0.875rem',
                },
              },
            }}
          >
            <MenuItem
              key={"option.path"}
              value={'GERENCIAMENTO'}
              hidden
              sx={{
                fontSize: '0.875rem',
                '&:hover': {
                  backgroundColor: '#333',
                },
              }}>
              <div
                style={{
                  color: `${translatePage(localStorage?.getItem('navbarState')) !== 'GERENCIAMENTO' ? 'rgb(0, 174, 239)' : width >= 990 ? 'white': 'black'}`,
                  textDecoration: 'none',
                  display: 'block',
                  width: 'max-content',
                  padding:  width >= 1210 ? '0.5rem 0rem' : '0.4rem 0rem',
                  fontSize: width >= 1210 ? '1rem' : width >= 990 ? '.6rem' : width <= 900 ? '12px' : '16px' ,
                  textTransform: width >= 990 ? 'uppercase' : 'none',
                }}
              >
                Gerenciamento
              </div>
            </MenuItem>
            {page.paths?.map((option: MenuProps) => (
              option.name !== 'GERENCIAMENTO' && ( // Condição para não exibir GERENCIAMENTO na lista
                <MenuItem
                  key={option.path}
                  value={option.path}
                  sx={{
                    fontSize: width > 1210 ? '1rem' :  width >= 990 ? '.6rem' : '16px',
                    '&:hover': {
                      backgroundColor:  width > 990 ?  '#333': '',
                    },
                  }}
                  onClick={() => handleButtonClick(option.path || '/')}
                >
                  <Link
                    to={option.path || '/'}
                    style={{
                      color: activeButton === option.path ? '#00AEEF' : width >= 990 ? 'white' : 'black',
                      textDecoration: 'none',
                      display: 'block',
                      width: 'max-content',
                      padding: '0.5rem 0rem',
                      fontSize: width >= 1210 ? '1rem' : width >= 990 ? '.6rem' : width <= 900 ? '12px' : '16px' ,
                      textTransform:  width >= 990 ? 'uppercase': 'none'
                    }}
                  >
                    {option.name}
                  </Link>
                </MenuItem>
              )
            ))}

          </Select>
        </FormControl>
      );
    } else {
      return (
        <Link
          to={page.path || '/'}
          style={{ color: activeButton === page.path ? '#00AEEF' : width >= 990 ? 'white' : 'black', height: '100%', padding: '0rem 0' }}
          onClick={() => handleButtonClick(page.path || '/')}
        >
          {page.name}
        </Link>
      );
    }
  };

  const settings: MenuProps[] = [
    { name: 'Minha conta', path: `/my-account/${user?.id}` },
    ...(content?.is_representative ? [
      { name: 'Minha empresa', path: `/company/${user?.company_id}` },
    ] : [])
  ];

  const userLinkConfig = {
    admin: [
      { name: 'Empresas', path: '/companies' },
      { name: 'Usuários', path: '/userslist/' },
      { name: 'Histórico', path: '/histories' },
      { name: 'Documentação', path: '/doc' },
      { name: 'Estatísticas', path: '/statistics' },
      ...(content?.can_upload_table || content?.can_manage_query_chassi_or_plate ? [
        {
          name: 'Gerenciamento',
          paths: [
            ...(content?.can_upload_table ? [{ name: 'Planilhas', path: '/new-table' }, { name: 'Buscadores', path: '/curve_leveling_graphic' }] : []),
            ...(content?.can_manage_query_chassi_or_plate ? [{ name: 'Precificador', path: '/pricing' }] : [])
          ]
        }
      ] : [])
    ],
    representative: [
      // { name: 'Minha empresa', path: `/company/${user?.company_id}` },
      { name: 'Usuários', path: `/users/company/${user?.company_id}` },
      { name: 'Histórico', path: '/histories' },
      { name: 'Estatísticas', path: '/statistics' }
    ],
    user: [
      { name: 'Histórico', path: `/histories` },
      { name: 'Estatísticas', path: '/statistics' }
    ]
  };

  const getPagesForUser = () => {
    if (content?.is_admin) {
      return userLinkConfig.admin
    }

    if (content?.is_representative) {
      return userLinkConfig.representative;
    }

    return userLinkConfig.user;
  };

  const pagesForUser = getPagesForUser();

  function OpenManagementModal() {
    setIsGerenciamentoOpen(!isGerenciamentoOpen)
  }

  return (
    <AppBar position="fixed" sx={{ backgroundColor: `${colors.DarkerGray}`, height: '81.33px', justifyContent: 'center' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link onClick={() => handleButtonClick("")} to={`/`}>
            <img src={require("../../images/horizontalLogo.png")} width={width > 355 ? 300 : 250} alt="Logo" />
          </Link>
          {

            width >= 990 ?
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pagesForUser.map((page) => (
                  <Button
                    onClick={() => {
                      if (page.name !== 'Gerenciamento') {
                        handleButtonClick(page.path || '/')
                      }
                    }}
                    className="boxButton"
                    key={page.name}
                    sx={{
                      my: 2,
                      color: activeButton === page.name ? '#00AEEF' : 'white',
                      display: 'block',
                    }}
                  >
                    {renderButtonOrSelect(page)}
                  </Button>
                ))}
              </Box>

              :

              <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'end' }} >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="primary"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  // sx={{
                  //   display: { xs: 'block', md: 'none' },
                  // }}
                >
                  <label style={{ marginLeft: 10, marginRight: 10, color: colors.WaterBlue }}>
                    <strong>Bem vindo(a), {user?.first_name} {user?.last_name} 😃</strong>
                  </label>
                  {pagesForUser.map((page) => (
                    <React.Fragment key={page.name}>
                      <MenuItem
                        onClick={() => {
                          if (page.name === 'Gerenciamento') {
                            OpenManagementModal()
                          } else {
                            handleButtonClick(page.path || '/')
                          }
                        }}>
                        {renderButtonOrSelect(page)}
                      </MenuItem>
                    </React.Fragment>
                  ))}
                  {settings.map((setting) => (
                    <MenuItem key={setting.name} onClick={() => handleButtonClick(setting.path || '')}>
                      <Link to={`/my-account/${user?.id}` || '/'} className="btn-link" >
                        <Typography textAlign="center" style={{ color : activeButton === setting.path ? '#00AEEF' : '' }}>{setting.name}</Typography> 
                      </Link>
                    </MenuItem>
                  ))}
                  <MenuItem key='sair' onClick={
                    () => {
                      setActiveButton(null)
                      setSelectedOption('GERENCIAMENTO')
                      Logout()
                      // navigate("/")
                    }
                  }
                  >
                    <Typography textAlign="center">Sair</Typography>
                  </MenuItem>
                </Menu>
              </Box>
          }
          {
            width >= 990 ?
              <Box sx={{ flexGrow: 0 }}>
                <strong style={{ color: 'white' }}>{user?.first_name} 😃</strong>
                <Tooltip title="Usuário">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user?.first_name} src={''} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <label style={{ marginLeft: 10, marginRight: 10, color: colors.WaterBlue }}>
                    <strong>Bem vindo, {user?.first_name} {user?.last_name} 😃</strong>
                  </label>
                  {settings.map((setting) => (
                    <MenuItem key={setting.name} onClick={() => handleButtonClick(setting.path || '')}>
                      <Typography textAlign="center" style={{ color : activeButton === setting.path ? '#00AEEF' : '' }}>{setting.name}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem onClick={() => Logout()}>
                    <Typography textAlign="center">Sair</Typography>
                  </MenuItem>
                </Menu>
              </Box> : <></>
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
}