import Core from "../Core";
import { ErrorModal } from "./Modal";
import * as api from "../../services/api";
import { useEffect, useState } from "react";
import { BiSolidError } from "react-icons/bi";
import { PiMicrosoftExcelLogoBold } from "react-icons/pi";

interface StatusComponentProps {
    keyProp: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    setRobotLocalStorage: React.Dispatch<React.SetStateAction<string>>;
}

function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() retorna 0-11
    const day = String(now.getDate()).padStart(2, '0');

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const ProcessedIn = (keyProp = '', status = '', processed = false) => {

    if (processed) {
        const processedKey = 'Processed_' + keyProp;
        localStorage.setItem(processedKey, `${getCurrentDateTime()}&&${status}`)
        return;
    }

    return;

}

function getCookie(name: any) {
    let cookie: any = {};

    document.cookie.split(';').forEach(function (el) {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    })

    return cookie[name];

}

const downloadXLSX = async (machine: string) => {

    const myCookie = getCookie("@Somos:access");

    let xlsxFileRoute = `/table/crawler/road/excel/?machine=${machine}`;

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'multiplataform/form-data',
            Authorization: `Bearer ${myCookie}`
        }
    };

    fetch(xlsxFileRoute, options)
        .then(response => {
            return response.blob()
        })
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'arquivo.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        })
        .catch(err => console.error(err));
}

const StatusComponent: React.FC<StatusComponentProps> = ({ keyProp, setErrorMessage, setRobotLocalStorage }) => {

    const [statusElement, setStatusElement] = useState<React.ReactNode>(<div className="flex items-center">-</div>);
    const [refreshCount, setRefreshCount] = useState(0);

    useEffect(() => {

        const fetchStatus = async () => {

            const task_id = localStorage.getItem(keyProp);

            if (task_id === null) {

                setStatusElement(
                    <div className="flex items-center">-</div>
                );

            } else {

                let urlParams;

                if(keyProp !== "semitrailer"){
                    urlParams = `/table/crawler/road/check_status/?task_id=${task_id}`;
                } else {
                    urlParams = `/table/crawler/agricultural/check_status/?task_id=${task_id}`;
                }

                try {

                    const respStatus: any = await api.get(urlParams);

                    if (respStatus.data.content.status === "PENDING") {
                        ProcessedIn('', '', false);
                        setStatusElement(
                            <div className="flex items-center">
                                <div className="w-4 h-4 bg-yellow-300 rounded-full mr-2"></div>PROCESSANDO
                            </div>
                        );
                    } else if (respStatus.data.content.status === "SUCCESS") {
                        setRobotLocalStorage((prevState: any) => ({
                            ...prevState,
                            [keyProp]: false
                        }));
                        ProcessedIn(keyProp, 'SUCCESS', true);
                        setStatusElement(
                            <div className="flex items-center">
                                <div className="w-4 h-4 bg-green-400 rounded-full mr-2"></div>SUCESSO
                            </div>
                        );
                    } else if (respStatus.data.content.status === "FAILED") {
                        setRobotLocalStorage((prevState: any) => ({
                            ...prevState,
                            [keyProp]: false
                        }));
                        setErrorMessage(respStatus.data.content.message);
                        ProcessedIn(keyProp, 'FAILED', true);
                        setStatusElement(
                            <div className="flex items-center">
                                <div className="w-4 h-4 bg-red-500 rounded-full mr-2"></div>FALHOU
                            </div>
                        );
                    } else {
                        if (localStorage.getItem(`Processed_${keyProp}`)) {
                            setStatusElement(
                                <div className="flex items-center">
                                    <div className="w-4 h-4 rounded-full mr-2"></div>-
                                </div>
                            );
                            return;
                        }
                        setRobotLocalStorage((prevState: any) => ({
                            ...prevState,
                            [keyProp]: false
                        }));
                        setStatusElement(
                            <div className="flex items-center">
                                <div className="w-4 h-4 rounded-full mr-2"></div>-
                            </div>
                        );
                    }

                }
                catch (error) {
                    if (localStorage.getItem(`Processed_${keyProp}`)) {
                        setStatusElement(
                            <div className="flex items-center">
                                <div className="w-4 h-4 rounded-full mr-2"></div>-
                            </div>
                        );
                        return;
                    }
                    ProcessedIn(keyProp, 'FAILED', true);
                    setRobotLocalStorage((prevState: any) => ({
                        ...prevState,
                        [keyProp]: false
                    }));
                    setErrorMessage('Ops, ocorreu um erro. Favor tente novamente mais tarde!');
                    setStatusElement(
                        <div className="flex items-center">
                            <div className="w-4 h-4 bg-red-500 rounded-full mr-2"></div>ERRO
                        </div>
                    );
                }
            }
        };

        fetchStatus();

    }, [statusElement, refreshCount]); // Atualiza ao mudar keyProp ou ao refrescar

    // Função para forçar a atualização
    const forceRefresh = () => {
        setRefreshCount(prev => prev + 1);
    };

    useEffect(() => {
        window.addEventListener('focus', forceRefresh); // Força atualização ao focar na janela

        return () => {
            window.removeEventListener('focus', forceRefresh); // Limpa o evento ao desmontar
        };
    }, []);

    return <>{statusElement}</>;
};

function SearchEngines() {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const setModal = (stateModal: boolean) => {
        setIsOpen(stateModal);
    };

    const [checkboxes, setCheckboxes] = useState<any>({
        crawler_tractor: false,
        small_loader: false,
        mini_excavator: false,
        loader: false,
        backhoe: false,
        harvester: false,
    });

    const [robotLocalStorage, setRobotLocalStorage] = useState<any>({
        crawler_tractor: localStorage.getItem('crawler_tractor') === null ? false : true,
        small_loader: localStorage.getItem('small_loader') === null ? false : true,
        mini_excavator: localStorage.getItem('mini_excavator') === null ? false : true,
        loader: localStorage.getItem('loader') === null ? false : true,
        backhoe: localStorage.getItem('backhoe') === null ? false : true,
        harvester: localStorage.getItem('harvester') === null ? false : true,
    })

    const Action = (keyProcess: string, machine: string) => {

        const statusProcess = keyProcess.split("&&");

        const wasProcessed = localStorage.getItem(keyProcess);

        if (wasProcessed) {
            if (statusProcess[1] === 'SUCCESS') {
                return <button onClick={() => downloadXLSX(machine)}><PiMicrosoftExcelLogoBold size={23} color={"rgb(248 113 113)"} /></button>
            } else if (statusProcess[1] === 'FAILED') {
                return <button onClick={() => setIsOpen(true)}><BiSolidError size={23} color={"rgb(248 113 113)"} /></button>
            } else { return '-' }

        }

        return '-'
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        Object.keys(checkboxes).map((item: string) => {
            if (checkboxes[item]) {
                Execute(item);
            }
        })
    }

    async function Execute(machine: string) {

        let machineParam = machine.trim();

        localStorage.removeItem(`Processed_${machineParam}`);

        let urlParams = "";

        if (machine === 'semitrailer') {
            urlParams = `/table/crawler/road?site=caminhoes_e_carretas&site=agrofy&site=cooper_cred&site=mercado_livre&site=mf_rural&site=mp_caminhoes&site=onibus_e_vans&site=so_tanques&site=trucado&site=machine=${machineParam}`;
        } else {
            urlParams = `/table/crawler/road?site=caminhoes_e_carretas&machine=${machineParam}`;
        }

        const resp: any = await api.get(urlParams);

        if (resp.status === 202) {
            localStorage.setItem(machine, resp.data.content.task_id);
        }

        return;

    }

    const handleCheckboxChange = (event: any) => {
        setCheckboxes({
            ...checkboxes,
            [event.target.name]: event.target.checked,
        });
        // setRobotLocalStorage((...prevState: any) => ({
        //     ...prevState,
        //     [event.target.name]: true
        // }));
    };

    const isAnyCheckboxSelected = Object.values(checkboxes).some(Boolean);

    return (
        <Core withNavbar withBlueBar path="/search-engines" label="">
            <ErrorModal setModal={setModal} state={isOpen} errorMessage={errorMessage} />
            <div className="h-full w-[100vw] overflow-x-auto flex justify-center py-9 text-gray-700 text-[14px] sm:text-[.9rem]">
                <div className="h-[max-content] w-[95vw] sm:w-[70vw] bg-white rounded-xl overflow-x-auto">
                    <form onSubmit={handleSubmit}>
                        <table className="w-full rounded-xl overflow-hidden shadow-sm min-w-[800px]">
                            <thead>
                                <tr className="bg-[#00AEEF] h-20 sm:h-14 font-semibold text-white text-md">
                                    <th className="w-[32%] px-4">BUSCADORES</th>
                                    <th className="w-[32%]">STATUS</th>
                                    <th className="w-[32%]">PROCESSADO EM</th>
                                    <th className="w-[4%] px-4">AÇÃO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-gray-50 h-20 sm:h-12">
                                    <td className="w-[32%] px-4">
                                        <label htmlFor="crawler_tractor" className="hover:cursor-pointer">
                                            <input type="checkbox" id="crawler_tractor" className="hover:cursor-pointer rounded-sm h-5 w-5 border-2 mr-4" onChange={handleCheckboxChange} checked={checkboxes.crawler_tractor} name={'crawler_tractor'} style={robotLocalStorage.crawler_tractor ? { background: 'gray' } : {}} disabled={robotLocalStorage.crawler_tractor} />
                                            TRATOR ESTEIRA
                                        </label>
                                    </td>
                                    <td className="w-[32%]"><StatusComponent setErrorMessage={setErrorMessage} setRobotLocalStorage={setRobotLocalStorage} keyProp='crawler_tractor' /></td>
                                    <td className="w-[32%]">{localStorage.getItem('Processed_crawler_tractor')?.split('&&')[0] || '-'}</td>
                                    <td className="w-[4%] px-4">{Action('Processed_crawler_tractor', 'crawler_tractor')}</td>
                                </tr>
                                <tr className="bg-white h-20 sm:h-12">
                                    <td className="w-[32%] px-4">
                                        <label htmlFor="semitrailer" className="hover:cursor-pointer">
                                            <input type="checkbox" id="semitrailer" className="hover:cursor-pointer rounded-sm h-5 w-5 border-2 mr-4" onChange={handleCheckboxChange} checked={checkboxes.semitrailer} name={'semitrailer'} style={robotLocalStorage.semitrailer ? { background: 'gray' } : {}} disabled={robotLocalStorage.semitrailer} />
                                            SEMIRREBOQUE
                                        </label>
                                    </td>
                                    <td className="w-[32%]"><StatusComponent setErrorMessage={setErrorMessage} setRobotLocalStorage={setRobotLocalStorage} keyProp='semitrailer' /></td>
                                    <td className="w-[32%]">-</td>
                                    <td className="w-[4%] px-4">{Action('Processed_semitrailer', 'semitrailer')}</td>
                                </tr>
                                <tr className="bg-gray-50 h-20 sm:h-12">
                                    <td className="w-[32%] px-4">
                                        <label htmlFor="small_loader" className="hover:cursor-pointer">
                                            <input type="checkbox" id="small_loader" className="hover:cursor-pointer rounded-sm h-5 w-5 border-2 mr-4" onChange={handleCheckboxChange} checked={checkboxes.small_loader} name={'small_loader'} style={robotLocalStorage.small_loader ? { background: 'gray' } : {}} disabled={robotLocalStorage.small_loader} />
                                            MINI CARREGADEIRA
                                        </label>
                                    </td>
                                    <td className="w-[32%]"><StatusComponent setErrorMessage={setErrorMessage} setRobotLocalStorage={setRobotLocalStorage} keyProp='small_loader' /></td>
                                    <td className="w-[32%]">{localStorage.getItem('Processed_small_loader')?.split('&&')[0] || '-'}</td>
                                    <td className="w-[4%] px-4">{Action('Processed_small_loader', 'small_loader')}</td>
                                </tr>
                                <tr className="bg-white h-20 sm:h-12">
                                    <td className="w-[32%] px-4">
                                        <label htmlFor="mini_excavator" className="hover:cursor-pointer">
                                            <input type="checkbox" id="mini_excavator" className="hover:cursor-pointer rounded-sm h-5 w-5 border-2 mr-4" onChange={handleCheckboxChange} checked={checkboxes.mini_excavator} name={'mini_excavator'} style={robotLocalStorage.mini_excavator ? { background: 'gray' } : {}} disabled={robotLocalStorage.mini_excavator} />
                                            MINI ESCAVADEIRA
                                        </label>
                                    </td>
                                    <td className="w-[32%]"><StatusComponent setErrorMessage={setErrorMessage} setRobotLocalStorage={setRobotLocalStorage} keyProp='mini_excavator' /></td>
                                    <td className="w-[32%]">{localStorage.getItem('Processed_mini_excavator')?.split('&&')[0] || '-'}</td>
                                    <td className="w-[4%] px-4">{Action('Processed_mini_excavator', 'Processed_mini_excavator')}</td>
                                </tr>
                                <tr className="bg-gray-50 h-20 sm:h-12">
                                    <td className="w-[32%] px-4">
                                        <label htmlFor="loader" className="hover:cursor-pointer">
                                            <input type="checkbox" id="loader" className="hover:cursor-pointer rounded-sm h-5 w-5 border-2 mr-4" onChange={handleCheckboxChange} checked={checkboxes.loader} name={'loader'} style={robotLocalStorage.loader ? { background: 'gray' } : {}} disabled={robotLocalStorage.loader} />
                                            PÁ CARREGADEIRA
                                        </label>
                                    </td>
                                    <td className="w-[32%]"><StatusComponent setErrorMessage={setErrorMessage} setRobotLocalStorage={setRobotLocalStorage} keyProp='loader' /></td>
                                    <td className="w-[32%]">{localStorage.getItem('Processed_loader')?.split('&&')[0] || '-'}</td>
                                    <td className="w-[4%] px-4">{Action('Processed_loader', 'loader')}</td>
                                </tr>
                                <tr className="bg-white h-20 sm:h-12">
                                    <td className="w-[32%] px-4">
                                        <label htmlFor="backhoe" className="hover:cursor-pointer">
                                            <input type="checkbox" id="backhoe" className="hover:cursor-pointer rounded-sm h-5 w-5 border-2 mr-4" onChange={handleCheckboxChange} checked={checkboxes.backhoe} name={'backhoe'} style={robotLocalStorage.backhoe ? { background: 'gray' } : {}} disabled={robotLocalStorage.backhoe} />
                                            RETROESCAVADEIRA
                                        </label>
                                    </td>
                                    <td className="w-[32%]"><StatusComponent setErrorMessage={setErrorMessage} setRobotLocalStorage={setRobotLocalStorage} keyProp='backhoe' /></td>
                                    <td className="w-[32%]">{localStorage.getItem('Processed_backhoe')?.split('&&')[0] || '-'}</td>
                                    <td className="w-[4%] px-4">{Action('Processed_backhoe', 'backhoe')}</td>
                                </tr>
                                <tr className="bg-gray-50 h-20 sm:h-12">
                                    <td className="w-[32%] px-4 ">
                                        <label htmlFor="harvester" className="hover:cursor-pointer">
                                            <input type="checkbox" id="harvester" className="hover:cursor-pointer rounded-sm h-5 w-5 border-2 mr-4" onChange={handleCheckboxChange} checked={checkboxes.harvester} name={'harvester'} style={robotLocalStorage.harvester ? { background: 'gray' } : {}} disabled={robotLocalStorage.harvester} />
                                            COLHEITADEIRA
                                        </label>
                                    </td>
                                    <td className="w-[32%]"><StatusComponent setErrorMessage={setErrorMessage} setRobotLocalStorage={setRobotLocalStorage} keyProp='harvester' /></td>
                                    <td className="w-[32%]">{localStorage.getItem('Processed_harvester')?.split('&&')[0] || '-'}</td>
                                    <td className="w-[4%] px-4">{Action('Processed_harvester', 'harvester')}</td>
                                </tr>
                                <tr className="bg-white h-20 sm:h-14">
                                    <td className="w-[32%] px-4"></td>
                                    <td className="w-[32%] px-4"></td>
                                    <td className="w-[32%] px-4"></td>
                                    <td className="w-[4%] px-4">
                                        <button className="font-semibold text-white bg-[#6fbf8c] px-4 py-2 rounded-md text-[14px] sm:text-[1rem]" style={!isAnyCheckboxSelected ? { background: 'lightgray' } : {}} disabled={!isAnyCheckboxSelected} type="submit">EXECUTAR</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </Core>
    );
}

export default SearchEngines;