import React, { useState, useRef, useEffect } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

interface InputFieldProps {
    error?: string;
    fieldNameRegister: string;
    register: UseFormRegister<any>;
    setValue: UseFormSetValue<any>;
    optionsProps: { [key: string]: string };
}

const MultiSelect = ({ fieldNameRegister, register, error, optionsProps, setValue }: InputFieldProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const options: Record<string, string> = { Todos: 'Todos', ...optionsProps };
    const ref = useRef<HTMLDivElement>(null);

    const toggleDropdown = (event: React.MouseEvent): void => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    const handleOptionChange = (option: string): void => {
        if (option === 'Todos') {
            if (selectedOptions.includes('Todos')) {
                setSelectedOptions([]);
            } else {
                setSelectedOptions(Object.keys(options).filter(opt => opt !== 'Todos'));
            }
        } else {
            if (selectedOptions.includes(option)) {
                setSelectedOptions(selectedOptions.filter((item) => item !== option));
            } else {
                setSelectedOptions([...selectedOptions, option]);
            }
        }
    };

    const handleClickOutside = (event: MouseEvent): void => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (selectedOptions.length === Object.keys(optionsProps).length && !selectedOptions.includes('Todos')) {
            setSelectedOptions([...selectedOptions, 'Todos']);
        } else if (selectedOptions.includes('Todos') && selectedOptions.length < Object.keys(optionsProps).length) {
            setSelectedOptions(selectedOptions.filter((item) => item !== 'Todos'));
        }
    }, [selectedOptions, optionsProps]);

    useEffect(() => {
        const optionKeys = Object.keys(optionsProps);
        const optionValues = Object.values(optionsProps);
        if (optionKeys.length === 1) {
            setValue("company", optionValues );
            setSelectedOptions([optionKeys[0]]);
        }
    }, [optionsProps, setValue]);

    return (
        <>
            <div className="relative w-full flex" ref={ref}>
                <div className="border border-gray-400 bg-white rounded-full h-11 p-2 cursor-pointer flex w-full" onClick={toggleDropdown}>
                    <div className="flex items-center justify-between w-full text-sm">
                        <span className="text-gray-700">
                            {selectedOptions.length > 1
                                ? selectedOptions.length > 2 
                                    ? `${selectedOptions.slice(0, 2).join(', ')}...`
                                    : selectedOptions.join(', ') 
                                : 'Selecionar opções'}
                        </span>
                        <svg className="w-4 h-4 font-bold text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                    </div>
                </div>
                {isOpen && (
                    <div className="absolute top-9 w-full border-gray-900 bg-white shadow-sm mt-1 z-10">
                        {['Todos', ...Object.keys(optionsProps)].map((key) => (
                            <label key={key} className="block p-2 cursor-pointer hover:bg-gray-100">
                                <input
                                    type="checkbox"
                                    className="mr-2 appearance-none h-4 w-4 border-gray-900 rounded-sm checked:bg-blue-600 checked:border-transparent focus:outline-none"
                                    value={key === 'Todos' ? '' : options[key]}
                                    checked={selectedOptions.includes(key)}
                                    {...register(fieldNameRegister)}
                                    onChange={() => handleOptionChange(key)}
                                />
                                {key}
                            </label>
                        ))}
                    </div>
                )}
            </div>
            {error && <p className='text-red-400'>{error}</p>}
        </>
    );
};

export default MultiSelect;