import { Doc } from "../pages/Doc"
import Histories from "../pages/Histories"
import Dashboard from "../pages/Dashboard"
import MyAccount from "../pages/User/MyAccount"
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import StatisticsScreen from "../pages/StatisticsScreen"
// import SearchEngines from "../pages/SearchEngines"
import SearchEngines from "../pages/SearchEngines"
import NavBar from "../components/NavBar"
import CurveLevelingGraphic from "../pages/SearchEngines/Curve_leveling_graphic"

export default function UserRoutes(): JSX.Element {

  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path='/doc' element={<Doc />} />
        <Route path='*' element={<Dashboard />}/>
        <Route path='/' element={<Dashboard />}/>
        <Route path='/histories' element={<Histories />} />
        <Route path='/statistics' element={<StatisticsScreen />} />
        <Route path='/search-engines' element={<SearchEngines />} />
        <Route path='/curve_leveling_graphic' element={<CurveLevelingGraphic />} />
        <Route path='/my-account/:id' element={<MyAccount />}/>
      </Routes>
    </BrowserRouter>
  )
}