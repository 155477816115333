import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { FaCloudArrowDown } from "react-icons/fa6";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import * as api from "../../../../services/api";


export default function Upload() {
    const [dragFile, setDragFile] = useState<any>(null);
    const [progress, setProgress] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedMachine, setSelectedMachine] = useState<string>('');
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);

    const machines = [
        { id: "semitrailer", name: "Semirreboque" }
    ];

    const handleMachineChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMachine(event.target.value);
    };

    const handleDraggingStateChange = (event: any) => {
        setDragFile(event);
    };

    const fileChanger = (file: File) => {
        if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            toast.error("Formato de arquivo inválido. Por favor, envie um arquivo .xlsx.", {
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }
        setFileToUpload(file);
    };

    const handleUpload = () => {
        if (!fileToUpload) {
            toast.warn("Nenhum arquivo selecionado.", {
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }

        if (!selectedMachine) {
            toast.warn("Nenhum produto selecionado.", {
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }

        setProgress(true);
        setIsUploading(true);
        uploadFile(fileToUpload).then(() => {
            setProgress(false);
            setIsUploading(false);
            toast.success("Upload realizado com sucesso!", {
                position: toast.POSITION.TOP_RIGHT
            })
            setFileToUpload(null);
        }).catch((error) => {
            setProgress(false);
            setIsUploading(false);
            toast.error("Erro no upload. Tente novamente!", {
                position: toast.POSITION.TOP_RIGHT
            })
        });
    };

    const uploadFile = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
            const response: any = await api.post(
              `/table/crawler/update/manual/${selectedMachine}/`,
              formData
            );
        
            return response.data;

        } catch (error) {
            console.error("Erro ao fazer upload:", error);
            throw error;
        }
    };

    return (
        <>
            <div className="flex p-4 bg-white rounded-b-xl">
                <div className="w-full fle justify-center">
                    <div className="flex items-start gap-6 sm:items-end flex-col justify-center sm:flex-row sm:justify-between">
                        
                        <div className=" w-[100%] sm:w-[50%]">
                            <FileUploader
                                className="w-screen"
                                progress={progress}
                                hoverTitle={
                                    <h5
                                    className="w-[100%] text-black text-center bg-[#6B7280]"
                                    >
                                        <FaCloudArrowDown /> Tudo certo! Pode soltar :)
                                    </h5>
                                }
                                onDraggingStateChange={handleDraggingStateChange}
                                multiple={false}
                                handleChange={fileChanger}
                                name={"file"}
                                types={["XLSX"]}
                            >
                                <div
                                    className="w-[21.3rem] md:w-[31rem] lg:w-[38rem] xl:w-[52rem] 2xl:w-[63rem] h-[3.8rem] p-[1rem] border-2 border-dashed border-[#808080] flex justify-center items-center text-[#808080] text-center rounded-full"
                                >
                                    {fileToUpload ? (
                                        <span>Arquivo selecionado: {fileToUpload.name}</span>
                                    ) : (
                                        <span className="flex gap-4 items-center">
                                            <FaCloudArrowDown size={25} /> Arraste seu arquivo aqui!
                                        </span>
                                    )}
                                </div>
                            </FileUploader>
                        </div>
                        <div className="w-[21.3rem] md:w-[18rem]">
                            {/* <label htmlFor="machine-select" className="block text-base font-bold text-[#6B7280]">
                                PRODUTO
                            </label> */}
                            <select
                                id="machine-select"
                                value={selectedMachine}
                                onChange={handleMachineChange}
                                className="bg-transparent mt-1 block w-full h-[3.8rem] border-2 border-gray-200 text-[#6B7280] font-semibold px-6 py-[1rem] rounded-full shadow-sm focus:outline-none focus:ring-gray-200 focus:border-gray-200 sm:text-sm"
                            >
                                <option value="" disabled selected>
                                    PRODUTO
                                </option>
                                {machines.map((machine) => (
                                    <option className="text-[#6B7280] font-semibold" key={machine.id} value={machine.id}>
                                        {machine.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="w-[100%] flex justify-center">

                        <button
                            className="bg-[#00AEEF] text-white h-[3.8rem] sm:w-[43%] rounded-full w-full mt-4 md:h-[3.2rem] md:w-[21.3rem]"
                            type="button"
                            disabled={isUploading}
                            onClick={handleUpload}
                        >
                            {isUploading ?
                                (
                                    <div className="flex justify-center items-center">
                                        <FaSpinner className="animate-spin text-white mr-2" size={30} />
                                        <span>Carregando</span>
                                    </div>)
                                : 'Carregar planilha'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}