import React from 'react';
//@ts-ignore
import styles from './styles.module.css';

const Pagination = ({ currentPage, totalPages, onPageChange }: any) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisibleButtons = 5;

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfMaxVisibleButtons = Math.floor(maxVisibleButtons / 2);

      let startPage = currentPage - halfMaxVisibleButtons;
      let endPage = currentPage + halfMaxVisibleButtons;

      if (startPage <= 0) {
        startPage = 1;
        endPage = maxVisibleButtons;
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxVisibleButtons + 1;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <div className={styles.numbersOfPage}>
        {pageNumbers.map((number) => (
          <button key={number} onClick={() => onPageChange(number)} className={styles.buttonPag} style={{background: currentPage === number ? '#0080af' : ''}}>
            {number}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.pagination}>
      <button className={styles.buttonPag} onClick={() => onPageChange(currentPage - 1)} style={{background: currentPage === 1 ? '#97e3ff' : ''}} disabled={currentPage === 1}>
        Anterior
      </button>
        {renderPageNumbers()}
      <button className={styles.buttonPag} onClick={() => onPageChange(currentPage + 1)} style={{background: currentPage === totalPages ? '#97e3ff' : ''}} disabled={currentPage === totalPages}>
        Próximo
      </button>
    </div>
  );
};

export default Pagination;