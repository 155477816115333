import { useState, useEffect, useRef } from 'react';

const months: Record<string, number> = { 'JAN': 1, 'FEV': 2, 'MAR': 3, 'ABR': 4, 'MAI': 6, 'JUN': 6, 'JUL': 7, 'AGO': 8, 'SET': 9, 'OUT': 10, 'NOV': 11, 'DEZ': 12 };
const years = Array.from({ length: 25 }, (_, i) => 2024 - i);

interface CustomSelectWithCheckboxProps {
    onSelectionChange: (options: any) => void; // Define o tipo da prop
    options: any
}

type monthYearRef = {
    reference_month: number;
    reference_year: number;
};

const CustomSelectWithCheckbox: React.FC<CustomSelectWithCheckboxProps> = ({ onSelectionChange, options }) => {

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [selectedOptionsNum, setSelectedOptionsNum] = useState<monthYearRef[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleCheckboxChange = (option: string) => {
        setSelectedOptions((prev) =>
            prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
        );

        const aux = option.split("/");
        const dataRef: monthYearRef = {
            reference_month: months[aux[0]], // Mês como número
            reference_year: parseInt(aux[1], 10), // Ano convertido explicitamente para número
        };

        setSelectedOptionsNum((prev) => {
            // Verifica se o objeto já existe no array
            const alreadyExists = prev.some(
                (item) =>
                    item.reference_month === dataRef.reference_month &&
                    item.reference_year === dataRef.reference_year
            );

            // Se existe, remove o objeto; senão, adiciona
            if (alreadyExists) {
                return prev.filter(
                    (item) =>
                        item.reference_month !== dataRef.reference_month ||
                        item.reference_year !== dataRef.reference_year
                );
            } else {
                return [...prev, dataRef]; // Adiciona o novo objeto
            }
        });
    };

    useEffect(() => {
        // Chama a função do componente pai sempre que selectedOptions mudar
        onSelectionChange(selectedOptionsNum);
    }, [selectedOptions, onSelectionChange]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="relative inline-block w-[100%] sm:w-[33%]" ref={dropdownRef}>

            <label htmlFor="" className='text-gray-500 font-semibold w-full'>
                Mês/Ano Referência
                <div
                    className="border-2 border-gray-200 rounded-full p-2 cursor-pointer bg-white text-gray-500 font-semibold h-11 sm:mt-0 flex text-sm"
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ alignItems: "center" }}
                >
                    SELECIONE OPÇÕES
                </div>
            </label>

            {isOpen && (
                <div className="absolute z-10 mt-1 w-full bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto">
                    {
                        options.map((item: any) => {
                            const monthNames = Object.fromEntries(
                                Object.entries(months).map(([key, value]) => [value, key])
                            );
                            const option = `${monthNames[item.reference_month]}/${item.reference_year}`;
                            return (
                                <label key={option} className="flex items-center p-2 hover:bg-gray-100 cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={selectedOptions.includes(option)}
                                        onChange={() => handleCheckboxChange(option)}
                                        className="mr-2"
                                    />
                                    {option}
                                </label>
                            );
                        })
                    }
                </div>
            )}

            {selectedOptions.length > 0 && (
                <div className="mt-4 w-full">
                    <h3 className="text-lg font-semibold mb-2">Opções Selecionadas:</h3>
                    <ul className="flex flex-wrap w-full sm:w-[90vw] gap-2">
                        {selectedOptions.map((option, index) => (
                            index > 5 ? <></> : index === 5 ? <li key={option} className="bg-white shadow px-3 py-1 rounded-full flex items-center w-[48%] sm:w-max">
                                ...
                                <button
                                    onClick={() => handleCheckboxChange(option)}
                                    className="ml-2 text-red-500 hover:text-red-700"
                                >

                                </button>
                            </li> :
                                <li key={option} className="bg-white shadow px-3 py-1 rounded-full flex items-center w-[48%] sm:w-max">
                                    {option}
                                    <button
                                        onClick={() => handleCheckboxChange(option)}
                                        className="ml-2 text-red-500 hover:text-red-700"
                                    >
                                        ✕
                                    </button>
                                </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CustomSelectWithCheckbox;