interface typeProp {
    state: boolean
    desc: string
    uuid: string
    handleModal: () => void
    excludeAdvertProp: (uuid: string) => void
}

function ExcludeModal({state, desc, uuid, excludeAdvertProp, handleModal}: typeProp) {

    const handleExcludeAdvert = () => {
        handleModal();
        excludeAdvertProp(uuid);
    }

    return (
        <div id="modal" className={`${state ? 'fixed' : 'hidden'} flex inset-0 z-50 items-center justify-center bg-opacity-5 backdrop-blur-[3px] w-full h-full p-6`}>
            <div className="relative w-full max-w-xl p-8 bg-white rounded-xl shadow-lg">
                <h2 className="text-xl font-bold text-gray-700">Exclusão de Anúncio</h2>
                <p className="mt-4 text-gray-700">Tem certeza que deseja excluir {desc} ?</p>
                <div className="flex gap-4 w-full justify-end">
                    <button className="bg-[#f56262] text-white h-7 rounded-full w-full sm:w-max py-2 px-4 flex justify-center items-center text-[12px] font-semibold mt-5" onClick={handleExcludeAdvert}>
                        EXCLUIR
                    </button>
                    <button className="bg-[#ffc772] text-white h-7 rounded-full w-full sm:w-max py-2 px-4 flex justify-center items-center text-[12px] font-semibold mt-5" onClick={handleModal}>
                        CANCELAR
                    </button>
                </div>
                <button id="closeModalButton" className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-[24px]" onClick={handleModal}>
                    &times;
                </button>
            </div>
        </div>
    )
}

export default ExcludeModal;