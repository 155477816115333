import { z } from "zod";
import { toast } from 'react-toastify';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ExcludeModal from "../ConfirmExclude";
import { IoDuplicate } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import * as api from '../../../../services/api';
import { IoIosArrowForward } from "react-icons/io";
import Pagination from "../../../Pricing/Pagination";
import { zodResolver } from "@hookform/resolvers/zod";
import CustomSelectWithCheckbox from "../MonthYearSelect";

interface StateOpenType {
    uuid: string
    state: boolean
}

const currentYear = new Date().getFullYear();

const yearFields = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => currentYear - i);

const filterInputSchema0 = z.object({
    product: z.string().min(1, 'Selecione um Produto.'),
    manuf: z.string().min(1, "Selecione um Fabricante."),
    model: z.string().min(1, "Selecione um Modelo."),
});

const filterInputSchema1 = z.object({
    FC: z.record(
        z.string().regex(/^\d{4}$/), // Valida que as chaves são anos (formato YYYY)
        z.coerce.number().min(0, "Deve ser maior ou igual 0."),        // Valores devem ser números positivos
    ),
    FN: z.string().min(1, "Adicione Fator de Nivelação."),
    fa_min: z.string().min(1, "Adicione o Valor Mínimo do Range."),
    fa_max: z.string().min(1, "Adicione o Valor Máximo do Range."),
});

const filterInputSchema2 = z.object({
    uuid: z.string().min(1),
    fabricator: z.string().min(1),
    model: z.string().min(1),
    year_model: z.coerce.number().min(4),
    price: z.coerce.number().min(1),
    month_year_ref: z.string().refine(
        (value) => /^\d{4}-\d{2}$/.test(value), // Validates 'YYYY-MM' format
        { message: "Invalid month format" }
    ),
    description: z.string().min(1),
});

type FormInputTypes0 = z.infer<typeof filterInputSchema0>;
type FormInputTypes1 = z.infer<typeof filterInputSchema1>;
type FormInputTypes2 = z.infer<typeof filterInputSchema2>;

type Manufs = {
    mounter_code: string,
    mounter: string
}

type Models = {
    mounter: string,
    model: string,
    model_code: string,
    description: string
}

type monthYearRef = {
    reference_month: number,
    reference_year: number,
}

type mockRes = {
    id: string,
    created_at: string,
    updated_at: string,
    price: number,
    url: string,
    crawl_date: string,
    description: string,
    fabricator: string,
    model: string,
    year_fabrication: number,
    year_model: number,
    worked_hours: null,
    optional: string,
    axis: number,
    color: string,
    vehicle_type: string,
    title: string,
    state: null,
    date_of_posting: null,
    pallets: null,
    code_model: string,
    year_reference: number,
    month_reference: 11,
    unit: null,
    size: number,
    is_active: boolean
}

window.onload = () => {
    localStorage.clear();
};

const CurveLeveling = () => {

    const {
        register: registerForm1,
        getValues: getValues1,
        setValue: setValue1,
        watch: watch1,
        handleSubmit: handleSubmit1,
        formState: { errors: errors1 },
    } = useForm<FormInputTypes1>({
        mode: "all",
        resolver: zodResolver(filterInputSchema1),
    });

    // Carregar dados do localStorage ao montar o formulário
    useEffect(() => {
        const savedData = localStorage.getItem("filterFormData");
        if (savedData) {
            try {
                const parsedData: FormInputTypes1 = JSON.parse(savedData);
                // Define os valores carregados no formulário
                Object.entries(parsedData).forEach(([key, value]) => {
                    setValue1(key as keyof FormInputTypes1, value);
                });
            } catch (error) {
                console.error("Erro ao carregar os dados do formulário:", error);
            }
        }
    }, [setValue1]);

    // Salvar dados no localStorage sempre que o formulário mudar
    useEffect(() => {
        const subscription = watch1((data) => {
            localStorage.setItem("filterFormData", JSON.stringify(data));
        });
        return () => subscription.unsubscribe();
    }, [watch1]);

    // Função de envio do formulário
    const onSubmit = (data: FormInputTypes1) => {
        console.log("Dados enviados:", data);
    };

    // Função auxiliar para carregar do localStorage
    const loadState = (key: string, defaultValue: any) => {
        try {
            const saved = localStorage.getItem(key);
            if (saved !== null) {
                return JSON.parse(saved);
            } else {
                return defaultValue;
            }
        } catch (error) {
            console.error(`Erro ao carregar ${key} do localStorage:`, error);
            return defaultValue;
        }
    };

    // Estados com valores carregados do localStorage
    const [desc, setDesc] = useState<string>(loadState('desc', ''));
    const [modalState, setModalState] = useState<boolean>(loadState('modalState', false));
    const [optionsState, setOptionsState] = useState<boolean>(loadState('optionsState', false));
    const [stateUUIDIndex, setstateUUIDIndex] = useState<StateOpenType>(loadState('stateUUIDIndex', { uuid: '', state: false }));
    const [currentPage, setCurrentPage] = useState<number>(loadState('currentPage', 1));
    const [totalPages, setTotalPages] = useState<number>(loadState('totalPages', 1));
    const [manufs, setManufs] = useState<Manufs[]>(loadState('manufs', []));
    const [models, setModels] = useState<Models[]>(loadState('models', []));
    const [modelCode, setModelCode] = useState<string>(loadState('modelCode', ''));
    const [childValue, setChildValue] = useState<monthYearRef[]>(loadState('childValue', []));
    const [monthYearOptions, setMonthYearOptions] = useState<any>(loadState('monthYearOptions', []));
    const [is_saved, setIs_saved] = useState<boolean>(loadState('is_saved', false));
    const [is_published, setIs_published] = useState<boolean>(loadState('is_published', false));
    const [mapValue, setMapValue] = useState<any>(loadState('mapValue', []));
    const [indexSelected, setIndexSelected] = useState<number>(loadState('indexSelected', 0));
    const [mock, setMock] = useState<[mockRes] | null>(loadState('mock', null));
    const [description, setDescription] = useState<string | null>(loadState('description', null));
    const [r2, setR2] = useState<string | null>(loadState('r2', null));

    // Salvar estados no localStorage quando algum deles mudar
    useEffect(() => {

        localStorage.setItem('desc', JSON.stringify(desc));
        localStorage.setItem('description', JSON.stringify(description));
        localStorage.setItem('r2', JSON.stringify(r2));
        localStorage.setItem('modalState', JSON.stringify(modalState));
        localStorage.setItem('optionsState', JSON.stringify(optionsState));
        localStorage.setItem('stateUUIDIndex', JSON.stringify(stateUUIDIndex));
        localStorage.setItem('currentPage', JSON.stringify(currentPage));
        localStorage.setItem('totalPages', JSON.stringify(totalPages));
        localStorage.setItem('manufs', JSON.stringify(manufs));
        localStorage.setItem('models', JSON.stringify(models));
        localStorage.setItem('modelCode', JSON.stringify(modelCode));
        localStorage.setItem('childValue', JSON.stringify(childValue));
        localStorage.setItem('monthYearOptions', JSON.stringify(monthYearOptions));
        localStorage.setItem('is_saved', JSON.stringify(is_saved));
        localStorage.setItem('is_published', JSON.stringify(is_published));
        localStorage.setItem('mapValue', JSON.stringify(mapValue));
        localStorage.setItem('indexSelected', JSON.stringify(indexSelected));
        localStorage.setItem('mock', JSON.stringify(mock));

    }, [
        desc,
        description,
        modalState,
        optionsState,
        stateUUIDIndex,
        currentPage,
        totalPages,
        manufs,
        models,
        modelCode,
        childValue,
        monthYearOptions,
        is_saved,
        is_published,
        mapValue,
        indexSelected,
        mock
    ]);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => currentYear - i);

    const {
        register: registerForm0,
        getValues: getValues0,
        handleSubmit: handleSubmit0,
        formState: { errors: errors0 },
    } = useForm<FormInputTypes0>({
        mode: "all",
        resolver: zodResolver(filterInputSchema0),
        defaultValues: {
            manuf: undefined,
            product: undefined,
            model: undefined
        }
    });

    const {
        register: registerForm2,
        setValue: setValue2,
        handleSubmit: handleSubmit2,
        reset: reset2,
        formState: { errors: errors2 },
    } = useForm<FormInputTypes2>({
        mode: "all",
        resolver: zodResolver(filterInputSchema2),
        defaultValues: {
            fabricator: "",
            model: "",
            year_model: 0,
            price: 0,
            month_year_ref: "06-2024",
            description: "",
        }
    });

    const handleValueFromChild = (value: monthYearRef[]) => {
        setChildValue(value);
    };

    const getValuesFromAPI = async (model: any, reference_dates: any) => {

        let dataBody = {
            reference_dates: childValue
        };

        const apiURL = `/table/crawler/records/list/task/semitrailer/?page=${currentPage}&page_size=10`;
        const uuid: any = await api.post(apiURL, dataBody);

        const resp: any = await api.get(`/table/crawler/check_status/?task_id=${uuid.data.content.task_id}&page=${currentPage}&page_size=10`)

        setMock(resp.data.content.result.records);

    }

    const excludeAdvert = async (uuid: string) => {

        const data = [
            uuid
        ]

        await api.post("/table/crawler/records/deactivate/task/semitrailer/", data);

        removeItemById(uuid);

    }

    const editionForm = (data: FormInputTypes2) => {

        const month_year = data.month_year_ref.split('-');

        const records = [
            {
                record_id: data.uuid.replace("#", ""),
                data: {
                    year_model: data.year_model,
                    price: data.price,
                    month_reference: Number(month_year[1]),
                    year_reference: Number(month_year[0]),
                    description: data.description,
                    axis: 0,
                    size: 0,
                    unit: "",
                }
            }
        ]

        let url = "";

        if (stateUUIDIndex.uuid.charAt(stateUUIDIndex.uuid.length - 1) === "#") {
            url = "/table/crawler/records/duplicate/task/semitrailer/";
            api.post(url, { records }).then((res: any) => {

                toast.success(res.data.content.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                removeItemById(data.uuid);

            }).catch((error: any) => {
                toast.error("Ops, ocorreu um erro! Tente novamente mais tarde", {
                    position: toast.POSITION.TOP_RIGHT
                })
            });
        } else {
            url = "/table/crawler/records/update/semitrailer/";
            api.put(url, { records }).then((res: any) => {
                toast.success(res.data.content.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }).catch((error: any) => {
                toast.error("Ops, ocorreu um erro! Tente novamente mais tarde", {
                    position: toast.POSITION.TOP_RIGHT
                })
            });
        }

    }

    const getProd = async (prod: string) => {

        const resp: any = await api.get(`table/search/mounter/3/`).then((res: any) => {
            return res;
        }).catch((error: any) => {

            toast.error("Ops, ocorreu um erro! Tente novamente mais tarde", {
                position: toast.POSITION.TOP_RIGHT
            })

        });

        await getManuf(resp.data.content);
        return;
    }

    const getManuf = async (manufs: any) => {
        setManufs(manufs);
        return;
    }

    const getModel = async (manufCode: string) => {

        await api.get(`table/search/codes/3/${manufCode}/`).then((response: any) => {
            setModels(response.data.content);
        }).catch((error: any) => {
            toast.error("Ops, ocorreu um erro! Tente novamente mais tarde", {
                position: toast.POSITION.TOP_RIGHT
            })
        });
    }

    const getModelCode = async (modelCode: string) => {
        setModelCode(modelCode);
        await handleMonthYearOptions(modelCode);
    }

    const removeItemById = (id: string) => {
        setMock((prevMock: any) => {
            // Encontra o índice do item com o id fornecido
            const index = prevMock.findIndex((item: any) => item.id === id);

            if (index !== -1) {
                // Cria uma cópia do array sem o item encontrado
                const newMock = [...prevMock];
                newMock.splice(index, 1);

                return newMock;
            }

            // Retorna o estado original se o id não for encontrado
            return prevMock;
        });
    };

    const duplicateItemByDescription = (description: string) => {

        setMock((prevMock: any) => {

            const index = prevMock.findIndex((item: any) => item.description === description);

            if (index !== -1) {

                setstateUUIDIndex({ uuid: '', state: false });

                const stateUUIDItem = {

                    ...prevMock[index],
                    month_reference: prevMock[index].month_reference,
                    year_reference: prevMock[index].year_reference,
                    price: "",
                    year_model: "",
                    id: prevMock[index].id + "#",

                };

                const newMock = [...prevMock];
                newMock.splice(index + 1, 0, stateUUIDItem);

                return newMock;
            }

            return prevMock;

        });

    };

    const handleModalState = () => {
        setModalState(!modalState);
    }

    const handleModal = (uuid: string, desc: string) => {

        setstateUUIDIndex({ uuid: uuid, state: true });
        setDesc(`${desc}`);
        setModalState((prevState) => !prevState ? true : prevState);

        return null;

    }

    const toggleEditionState = (uuid: string, desc: string, cancel = false) => {

        setDesc(`${desc}`);
        setstateUUIDIndex({ uuid: uuid, state: true });

        if (stateUUIDIndex === null) {
            setstateUUIDIndex({ uuid: uuid, state: true })
        } else if (stateUUIDIndex.uuid === uuid) {
            setstateUUIDIndex({ uuid: '', state: false });
        } else if (stateUUIDIndex !== null && cancel) {
            setstateUUIDIndex({ uuid: '', state: false });
        }

        return null;

    };

    const finalCalcFunc = async (model: any, dataBody: any) => {

        const resp: any = await api.post(`/table/crawler/calc/final/semitrailer/${model}/?is_saved=${is_saved}&is_published=${is_published}`, dataBody).then((res: any) => {
            toast.success(res.data.content.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return res;
        });

        const uuid = resp.data.content.task_id;

        const respGet: any = await api.get(`/table/crawler/check_status/?task_id=${uuid}`);

        const finalCalc = respGet.data.content.result.calculation_results.results;
        const [, firstValue] = Object.entries(finalCalc)[0] as [string, Record<string, any>];

        setR2(Object.values(firstValue)[0].final_calc.R2);

        return firstValue;
    }

    const handleAll = async (data: FormInputTypes1) => {

        let dataBody = {
            fa_min: data.fa_min,
            fa_max: data.fa_max,
            FN: data.FN,
            FC: data.FC,
            reference_dates: childValue
        };

        const resp = await finalCalcFunc(modelCode, dataBody);

        setMapValue(Object.values(resp));

    }

    const handleGetFilters = async (data: FormInputTypes0) => {

        const model0 = data.model;
        setModelCode(model0);

        if (childValue.length === 0) return;

        await api.get(`/table/crawler/calc/factors/semitrailer/${model0}/`).then((res: any) => {
            setValue1("fa_min", res.data.content.fa_min);
            setValue1("fa_max", res.data.content.fa_max);
            setValue1("FN", res.data.content.FN);
            setValue1("FC", res.data.content.FC);

        }).catch((err: any) => {
            toast.error("Ops, ocorreu um erro! Tente novamente mais tarde", {
                position: toast.POSITION.TOP_RIGHT
            })
        });

        let dataBody = {
            fa_min: getValues1("fa_min"),
            fa_max: getValues1("fa_max"),
            FN: getValues1("FN"),
            FC: getValues1("FC"),
            reference_dates: childValue
        };

        getValuesFromAPI(model0, childValue);

        const resp = await finalCalcFunc(model0, dataBody);

        setMapValue(Object.values(resp));

    }

    const handleMonthYearOptions = async (modelCode: string) => {
        await api.get(`/table/crawler/calc/reference_dates/semitrailer/${modelCode}/`).then((res: any) => { setMonthYearOptions(res.data.content) }).catch((err: any) => {
            toast.error("Ops, ocorreu um erro! Tente novamente mais tarde", {
                position: toast.POSITION.TOP_RIGHT
            })
        });
    }

    useEffect(() => {
        if (stateUUIDIndex?.uuid && mock !== null) {
            const itemToEdit = mock?.find(item => item.id === stateUUIDIndex.uuid);
            if (itemToEdit) {
                reset2({
                    fabricator: itemToEdit.fabricator,
                    model: itemToEdit.model,
                    year_model: itemToEdit.year_model,
                    price: itemToEdit.price,
                    month_year_ref: `${itemToEdit.year_reference}-${itemToEdit.month_reference}`,
                    description: itemToEdit.description
                });
            }
        }
    }, [stateUUIDIndex, reset2, mock]);

    const findDataByYear = (mapValue2: any, year: number) => {
        const foundItem = mapValue2.find((item: any) => item.year_model === year);
        const index = foundItem ? mapValue2.findIndex((item: any) => item.year_model === year) : -1;
        return { index, foundItem }; // Retorna tanto o index quanto o foundItem
    };

    const handlePageChange = (newPage: number) => {
        setIndexSelected(0);
        setCurrentPage(newPage);
    };

    return (
        <div className="h-max w-ful  rounded-b-xl bg-white">
            <div className="h-max w-full flex align-center justify-center">
                <div className=" py-8 px-6 sm:px-12 w-[92vw] h-max rounded-b-xl">
                    <h5 className="text-[24px] font-semibold text-gray-600">FATOR DE CURVA E NIVELAÇÃO</h5>
                    <div>
                        <form className="flex flex-col w-full sm:w-[60%]" onSubmit={handleSubmit0(handleGetFilters)} >
                            <div className="">
                                <div className="flex flex-col sm:flex-row mt-12 w-full">
                                    <label htmlFor="" className="flex flex-col w-full sm:w-[33%] text-gray-500 font-semibold">
                                        Produto
                                        <select id="" className="px-2 rounded-full sm:rounded-none sm:rounded-l-full w-full border-2 border-gray-200 text-[14px] font-semibold text-gray-500 h-11" {...registerForm0("product")} onChange={(e) => getProd(e.target.value)}>
                                            <option value="FABRICANTE" selected hidden>PRODUTO</option>
                                            <option value="semitrailer">SEMIRREBOQUE</option>
                                        </select>
                                    </label>
                                    <label htmlFor="" className="flex flex-col w-full sm:w-[33%] text-gray-500 font-semibold">
                                        Fabricante
                                        <select id="" className="px-2 w-full border-2 rounded-full sm:rounded-none border-gray-200 text-[14px] font-semibold text-gray-500 h-11" {...registerForm0("manuf")} onChange={(e) => { getModel(e.target.value) }}>
                                            <option value="FABRICANTE" selected hidden>FABRICANTE</option>
                                            {manufs?.map((item: any) => {
                                                return <option value={item.mounter_code}>{item.mounter}</option>
                                            })}
                                        </select>
                                    </label>
                                    <label htmlFor="" className="flex flex-col w-full sm:w-[33%] text-gray-500 font-semibold">
                                        Modelo
                                        <select id="" className="px-2 rounded-full sm:rounded-none sm:rounded-r-full w-full border-2 border-gray-200 text-[14px] font-semibold text-gray-500 h-11" {...registerForm0("model")} onChange={(e) => {
                                            getModelCode(e.target.value);
                                            const selectedItem = models?.find((item: any) => item.model_code === e.target.value);
                                            if (selectedItem) {
                                                console.log(selectedItem);
                                                setDescription(selectedItem.description);
                                            } else {
                                                setDescription("");
                                            }
                                        }}>
                                            <option value="MODELO" selected hidden>MODELO</option>
                                            {models?.map((item: any) => {
                                                return <option value={item.model_code}>{item.model}</option>
                                            })}
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className="w-full sm:w-[100%] flex flex-col sm:flex-row justify-start items-start align-top gap-2 sm:pr-3 sm:mt-12">
                                <CustomSelectWithCheckbox onSelectionChange={handleValueFromChild} options={monthYearOptions} />

                                <div className="flex items-end w-full sm:w-[33%]"><button className="bg-[#6fbf8c] text-white h-11 rounded-full w-full py-4 px-10 flex justify-center items-center text-[14px] font-semibold mt-6" type="submit" onClick={() => { setIs_published(false); setIs_saved(false) }} >FILTRAR</button></div>
                            </div>


                        </form>
                        <form onSubmit={handleSubmit1(handleAll)}>
                            <div className="mt-12 w-full text-left text-gray-400 mb-2">

                                <small>
                                    Dados gerados em 26 de Agosto de 2024.
                                </small>

                            </div>
                            <div className="rounded-lg divTable mb-5 border-2 border-gray-200">
                                <table className="min-w-full border-2 border-gray-500 rounded-sm tableFilter">
                                    <thead className="rounded-full">
                                        <tr className="border-2 w-full rounded-full">
                                            <th className="bg-gray-600 rounded-tl-xl">FATOR DE NIVELAÇÃO</th>
                                            <th className="bg-red-500"><input type="text" className="w-full border-2 border-blue-400 bg-gray-50 h-full focus:outline-none px-2" {...registerForm1("FN")} /></th>
                                            <th className="bg-gray-600">FATOR ALEATÓRIO - RANGE</th>
                                            <th className="bg-gray-600"><input type="text" className="w-full border-2 border-blue-400 bg-gray-50 h-full focus:outline-none px-2" {...registerForm1("fa_min")} /></th>
                                            <th className="bg-gray-600"><input type="text" className="w-full border-2 border-blue-400 bg-gray-50 h-full focus:outline-none px-2" {...registerForm1("fa_max")} /></th>
                                        </tr>
                                        <tr className="border-2 w-full ">
                                            <th></th>
                                            {
                                                yearFields.map(item => <th>{item}</th>)
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="font-semibold text-gray-600">FATOR DE CURVA</td>
                                            {
                                                years.map((year: number) => {
                                                    const { index, foundItem } = findDataByYear(mapValue, year); // Desestrutura ambos, index e foundItem

                                                    if (foundItem) {
                                                        return (
                                                            <td className="max-w-[120px]" key={year}>
                                                                <input
                                                                    type="number"
                                                                    step="any"
                                                                    className="h-full w-full p-0 border-none outline-none"
                                                                    {...registerForm1(`FC.${year}`)} // Fazendo o registro do ano no formulário
                                                                />
                                                                {errors1.FC?.[year] && <span>{errors1.FC[year]?.message}</span>}
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td className="max-w-[120px] min-w-[80px]" key={year}>
                                                                -
                                                            </td>
                                                        );
                                                    }
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td className="font-semibold text-gray-600">PREÇO SOMOS + FA</td>

                                            {
                                                yearFields.map((year: any) => {
                                                    const { index, foundItem } = findDataByYear(mapValue, year);
                                                    if (foundItem) return <td>R$ {mapValue[index].final_calc.PSB}</td>
                                                    else return <td className="max-w-[120px] min-w-[80px]">-</td>
                                                })
                                            }

                                        </tr>
                                        <tr>
                                            <td className="font-semibold text-gray-600">PREÇO SOMOS</td>
                                            {
                                                yearFields.map((year: any) => {
                                                    const { index, foundItem } = findDataByYear(mapValue, year);
                                                    if (foundItem) return <td>R$ {mapValue[index].final_calc.PS}</td>
                                                    else return <td className="max-w-[120px] min-w-[80px]">-</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td className="font-semibold text-gray-600">MÉDIA DE MERCADO</td>
                                            {
                                                yearFields.map((year: any) => {
                                                    const { index, foundItem } = findDataByYear(mapValue, year);
                                                    if (foundItem) return <td>R$ {mapValue[index].MM}</td>
                                                    else return <td className="max-w-[120px] min-w-[80px]">-</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            {
                                                years.map(() => <td></td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="-mt-4 w-full flex flex-col sm:flex-row align-center justify-end sm:gap-4">

                                <button className='bg-[#6fbf8c] text-white h-11 rounded-full w-full sm:w-max py-4 px-10 flex justify-center items-center text-[14px] font-semibold mt-5' type="submit" onClick={() => { setIs_saved(true); setIs_published(false) }}>SALVAR</button>
                                <button className='bg-[#00AEEF] text-white h-11 rounded-full w-full sm:w-max py-4 px-10 flex justify-center items-center text-[14px] font-semibold mt-5' type="submit" onClick={() => { setIs_saved(false); setIs_published(true) }}>PUBLICAR</button>

                            </div>
                        </form>
                    </div>

                    <div className={`w-full flex flex-col gap-8 mt-12 justify-start align-top ${optionsState ? 'shrinkHeightOnlyClass' : 'increaseHeightOnlyClass'}`}>

                        <ExcludeModal handleModal={handleModalState} state={modalState} desc={desc} uuid={stateUUIDIndex?.uuid} excludeAdvertProp={excludeAdvert} />

                        <div className="flex items-center" onClick={() => { setOptionsState(!optionsState) }}>
                            {
                                optionsState ? <IoIosArrowDown /> : <IoIosArrowForward />
                            }
                            <button className="w-max font-semibold text-gray-600 px-4 py-2 rounded-full z-0">LISTA DE ANÚNCIOS INDIVIDUAIS</button>
                        </div>
                        <div className="overflow-hidden">
                            <div className={`${optionsState ? "increaseHeightClass" : "shrinkHeightClass"} containerProds z-1`}>
                                {
                                    // stateUUIDIndex! == null ?
                                    mock?.map((item, index) => {

                                        return (
                                            <div className="p-4 border-2 box-shadow border-gray-100  rounded-lg flex justify-between w-full font-semibold">
                                                <div className={`transition-all duration-500 ease-in-out w-full`}>
                                                    <div className="flex flex-col sm:flex-row justify-between w-full">
                                                        <h1 className="text-xl text-gray-600">{item.fabricator} - {item.model} | ANO MODELO: {item.year_model}</h1>
                                                        <div className="mt-2 sm:-mt-4 w-full sm:w-max flex gap-4 justify-between">
                                                            <button className={`bg-[#00AEEF] text-white h-7 rounded-full w-full sm:w-max py-2 px-4 justify-center items-center text-[12px] font-semibold mt-5 ${item.id[item.id.length - 1] === '#' ? 'hidden' : 'flex'} `} title='Duplicar' onClick={() => duplicateItemByDescription(item.description)}><IoDuplicate /></button>
                                                            <button className="bg-[#ffc56f] text-white h-7 rounded-full w-full sm:w-max py-2 px-4 flex justify-center items-center text-[12px] font-semibold mt-5" title='Editar' onClick={() => toggleEditionState(item.id, item.description)}><FaEdit /></button>
                                                            <button className="bg-[#ff7070] text-white h-7 rounded-full w-full sm:w-max py-2 px-4 flex justify-center items-center text-[12px] font-semibold mt-5" title='Deletar' onClick={() => handleModal(item.id, item.description)}><MdDelete /></button>
                                                        </div>
                                                    </div>

                                                    {
                                                        stateUUIDIndex?.uuid === item.id
                                                            ?
                                                            <form className="flex flex-col text-gray-500" onSubmit={handleSubmit2(editionForm)}>
                                                                <div className="flex flex-col sm:flex-row sm:gap-24 mt-12 w-full">
                                                                    <div className="w-full sm:w-[50%]">
                                                                        <input type="text" className="border-2 rounded-full px-2 h-11 w-full sm:w-[70%] focus:outline-none hidden" value={item.id}  {...registerForm2('uuid')} />
                                                                        <label htmlFor="" className="flex flex-col w-full mt-2">
                                                                            Nome Fabricante
                                                                            <input type="text" className="border-2 rounded-full px-2 h-11 w-full sm:w-[70%] focus:outline-none border-gray-300" value={item.fabricator} />
                                                                        </label>
                                                                        <label htmlFor="" className="flex flex-col w-full mt-2">
                                                                            Modelo
                                                                            <input type="text" className="border-2 rounded-full px-2 h-11 w-full sm:w-[70%] focus:outline-none border-gray-300" value={item.model} />
                                                                        </label>
                                                                        <label htmlFor="" className="flex flex-col w-full mt-2">
                                                                            Ano Modelo
                                                                            <input type="text" className="border-2 rounded-full px-2 h-11 w-full sm:w-[70%] focus:outline-none border-gray-300" {...registerForm2('year_model')} />
                                                                        </label>
                                                                    </div>

                                                                    <div className="w-full sm:w-[50%]">
                                                                        <label htmlFor="" className="flex flex-col w-full mt-2">
                                                                            Valor
                                                                            <input type="text" className="border-2 rounded-full px-2 h-11 w-full sm:w-[70%] focus:outline-none border-gray-300" {...registerForm2('price')} />
                                                                        </label>
                                                                        <label htmlFor="" className="flex flex-col w-full mt-2">
                                                                            Mês/Ano Referência
                                                                            <input type="month" className="border-2 rounded-full px-2 h-11 w-full sm:w-[70%] focus:outline-none border-gray-300" {...registerForm2('month_year_ref')} />
                                                                        </label>
                                                                        <label htmlFor="" className="flex flex-col w-full mt-2">
                                                                            Descrição
                                                                            <input type="text" className="border-2 rounded-full px-2 h-11 w-full sm:w-[70%] focus:outline-none border-gray-300" {...registerForm2('description')} />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-col sm:flex-row sm:gap-4 sm:mt-12 mb-4">
                                                                    <button className="bg-[#ffc772] text-white h-7 rounded-full w-full sm:w-max py-2 px-4 flex justify-center items-center text-[12px] font-semibold mt-5" onClick={() => toggleEditionState(item.id, item.description, true)}>CANCELAR</button>
                                                                    <button className="bg-[#6fbf8c] text-white h-7 rounded-full w-full sm:w-max py-2 px-4 flex justify-center items-center text-[12px] font-semibold mt-5" type="submit">SALVAR</button>
                                                                </div>
                                                            </form>
                                                            :
                                                            <></>
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })

                                }
                                {
                                    (mock !== null) ? < div className="w-full flex flex-col gap-4 justify-end items-end" >
                                        {/* <button className="bg-[#6fbf8c] text-white h-11 rounded-full w-full sm:w-max py-4 px-10 flex justify-center items-center text-[14px] font-semibold mt-5">SALVAR</button> */}
                                        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />

                                    </div> : <></>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default CurveLeveling;