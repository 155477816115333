/* eslint-disable react-hooks/exhaustive-deps */
import Core from "../Core"
import Pagination from "./Pagination"
import { toast } from "react-toastify"
import * as api from "../../services/api"
import { useAuth } from "../../hooks/useAuth"
//@ts-ignore
import styles from "./styles.module.css"
import { FaArrowAltCircleDown, FaSearch } from "react-icons/fa"
import { FaTrailer } from "react-icons/fa"
import { EditionModal } from "./EditionModal"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { DefaultMessage } from "../../utils/defaultMessages"
import { Fragment, useEffect, useRef, useState, RefObject } from "react"
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import { Avatar, Button, Collapse, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography, FormControl, Select, MenuItem, CircularProgress, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, IconButton, SelectChangeEvent, InputLabel } from "@mui/material"
import GetAppIcon from "@mui/icons-material/GetApp";
import useWindowSize from "../../hooks/useWindowSize"
import { relative } from "path"
import { useLocation } from "react-router-dom"
// import * as XLSX from "xlsx"
// import { AxiosResponse } from "axios"
// import { Buffer } from 'buffer'; 
// import JSZip from "jszip";

interface SearchItem {
    id: string,
    date: string | null,
    region: string | null,
    state: string | null,
    city: string | null,
    invoiced: string | null,
    fabricator: string | null,
    brand_model: string | null,
    year_fabrication: string | null,
    axis: string | null,
    product: string | null,
    type_vehicle: string | null,
    chassi: string | null,
    plate: string | null,
    month_registration: string | null,
    year_registration: string | null,
    somos_code: string | null
}

interface TableRowData {
    nomeArquivo: string;
    criadoPor: string;
    criadoEm: string;
    atualizadoEm: string;
    status: string;
    totalLinhas: number;
    processadasComSucesso: number;
    processadasComErro: number;
    exportarResultado: string;
}

// const useLocalStorageMonitor = (key: string) => {

//     const [value, setValue] = useState<string | null>(() => localStorage.getItem(key));

//     useEffect(() => {
//         const handleStorageChange = (event: StorageEvent) => {
//             if (event.key === key) {
//                 setValue(event.newValue);
//             }
//         };

//         window.addEventListener('storage', handleStorageChange);

//         return () => {
//             window.removeEventListener('storage', handleStorageChange);
//         };
//     }, [key]);

//     return value;
// };


interface LocationState {
    responseData?: any; // Defina o tipo correto aqui, se souber
}

interface TableRowData {
    column1: string;
    column2: string;
    column3: number;
    // Adicione outras colunas conforme a estrutura do seu Excel
}

// interface ApiResponse {
//     data: Blob;
// }

interface ApiResponse {
    data: string; // Substitua `string` pelo formato correto (como `Base64` ou um objeto mais complexo).
}

  
export const Pricing = () => {
    let [taskId, setTaskId] = useState<string | null>(localStorage.getItem('excel_task_id'));
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<string>('sea');
    const [totalPages, setTotalPages] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageAcc, setCurrentPageAcc] = useState<number>(1);
    const [somosCode, setSomosCode] = useState<number | string>('all');
    const [spreadRow, setSpreadRow] = useState<Array<any>>([]);
    const [indexSelected, setIndexSelected] = useState<number>(0);
    const [chassi_or_plate, setChassi_or_plate] = useState<string>('');
    const [dataSearch, setDataSearch] = useState<Array<SearchItem>>([]);
    const [fileSelected, setFileSelected] = useState<File | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const textFieldRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const [totalFounded, setTotalFounded] = useState(0);
    const [totalInDataBase, setTotalInDataBase] = useState(0);
    // const [initialDate, setInitialDate] = useState<string>('');
    // const [finalDate, setFinalDate] = useState<string>('');
    // const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    // const [tableData, setTableData] = useState<TableRowData[]>([]);
    const { user } = useAuth();
    const { width } = useWindowSize()
    const location = useLocation();

    const checkExcelTasIdStatus = () => {
        taskId = localStorage.getItem('excel_task_id');
        if (!taskId) {
            setTaskId(null);
        } else {
            setTaskId(taskId)
        }
    }

    // const excelTaskId = useLocalStorageMonitor('excel_task_id');

    // useEffect(() => {
    //     setTaskId(excelTaskId);
    // }, [excelTaskId]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const testJson = localStorage.getItem('task');
    let a = null
    if (testJson !== null) {
        a = JSON.parse(testJson);
    }

    const [spreadName, setSpreadName] = useState<string | null>(a ? a.task_id_name : null);
    const [spreadStatus, setSpreadStatus] = useState<string | null>(a ? a.task_id_status : null);

    const downloadXLSX = async (dataReq: any) => {
        let url = `?${new URLSearchParams(dataReq).toString()}`;
        let xlsxFileRoute = `${api.baseURL}/table/precificador/excel/filtered/data/param/${url}`;

        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user?.access}`
            }
        };

        fetch(xlsxFileRoute, options)
            .then(response => response.json())
            .then(json => {
                setTaskId(json.content.task_id);
                toast.info("A geração do arquivo Excel foi iniciada. Você será notificado quando estiver pronto.", {
                    position: toast.POSITION.TOP_RIGHT
                });
                json.content.task_id && localStorage.setItem('excel_task_id', json.content.task_id);
            })
            .catch(err => {
                console.error(err);
                toast.error("Erro ao iniciar a geração do arquivo semitrailers.xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

        setInterval(checkExcelTasIdStatus, 15000);
    }

    const reset = () => {
        setBrowserOrFileName('Planilha Atualizada');
        setFileSelected(undefined);
    }

    const task_id = (id: string) => {
        api.get(`/table/check/status/tasks/?task_id=${id}`)
            .then((res: any) => {
                const ptStatus = res.data.content.status === 'SUCCESS' ? 'Sucesso' : res.data.content.status === 'PENDING' ? 'Pendente' : 'Falhou';
                let jsonInfo: object = { task_id: id, task_id_name: spreadName, task_id_date: '', task_id_status: ptStatus }
                const jsonInfoString = JSON.stringify(jsonInfo);
                localStorage.setItem('task', jsonInfoString);
                setSpreadStatus(ptStatus);
            }).catch((error) => {
                toast.dismiss()
                toast.error("Algo deu errado, verifique se o arquivo possui terminação '.xlsx'", {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
    }

    const uploadUpdatedTable = () => {

        const data = new FormData();
        data.append("file", fileSelected!);

        api.post(
            `${api.baseURL}/table/upload/without/somoscodes/`,
            data,
            'multipart/form-data; boundary=---011000010111000001101001'
        ).then((response: any) => {
            if (response.status !== 200 && response.status !== 201 && response.status !== 202) {

                toast.dismiss()
                toast.warning(`${response.data.content.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
                reset()
            } else if (response.status === 202) {
                toast.dismiss()
                toast.warning(`${response.data.content.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
                reset()
                task_id(response.data.content.task_id);
                localStorage.setItem('taskID', response.data.content.task_id);
            } else {

                toast.dismiss()
                toast.success(`PLANILHA CADASTRADA!`, {
                    position: toast.POSITION.TOP_RIGHT
                })
                reset()
            }

            reset()

        }).catch((error) => {

            toast.dismiss()
            toast.error("Algo deu errado, verifique se o arquivo possui terminação '.xlsx'", {
                position: toast.POSITION.TOP_RIGHT
            })

            reset()

        })
    }
    const setModal = (stateModal: boolean) => {
        setIsOpen(stateModal);
    };

    const handlePageChange = (newPage: number) => {
        setIndexSelected(0)
        setCurrentPage(newPage);
    };

    const fetchData = async () => {

        try {
            const response: any = await api.get(`/table/license/plates/list/?page=${currentPage}`);
            let totalOfPages: number = response.data.content.pages;
            setTotalInDataBase(response.data.content.total);
            setTotalFounded(response.data.content.total);
            setLoading(false);
            setTotalPages(totalOfPages);
            setDataSearch(response.data.content.items);
        } catch (error) {
            toast.error(DefaultMessage.ERROR, {
                position: toast.POSITION.TOP_RIGHT
            })
        }

    }

    useEffect(() => {
        scrollToTop();
        setLoading(true);
        handleSearch(chassi_or_plate);
    }, [currentPage]);

    useEffect(() => {
        const id = localStorage.getItem('taskID');
        if (id) {
            task_id(id);
        }
        fetchData();
    }, []);

    type DataReq = {
        query?: string;
        somos_code?: number;
        page?: number;
        product?: string;
    }

    const handleSearch = async (event: string, download = false) => {

        setIndexSelected(0);
        // setCurrentPage(1);
        // setPage(1);

        
        if (!download) setLoading(true);
        
        let query = event;
        query.replaceAll(/[^\w\s]/gi, '').toUpperCase();
        
        let apiRoute = `/table/license/plates/list/`
        
        let dataReq: DataReq = {};
        
        try {
            await fetchData(); 
        } catch (error) {
            toast.error("Erro ao buscar os dados atualizados.", { position: toast.POSITION.TOP_RIGHT });
        } 
        if (query === "" && somosCode === 'all') {
            dataReq = {
            }
        } else if (query === "" && somosCode === 'false') {
            dataReq = {
                somos_code: 0,
            }
        } else if (query === "" && somosCode === 'true') {
            dataReq = {
                somos_code: 1,
            }
        } else if (query !== "" && somosCode === 'all') {
            dataReq = {
                query,
            }
        } else if (query !== "" && somosCode === 'false') {
            dataReq = {
                query,
                somos_code: 0,
            }
        } else if (query !== "" && somosCode === 'true') {
            dataReq = {
                query,
                somos_code: 1,
            }
        } else {
            apiRoute = `/table/license/plates/list/`;
        }

        if (download) {

            await downloadXLSX(dataReq);

        } else {
            try {
                dataReq = { ...dataReq, page: currentPage };
                const response: any = await api.get(apiRoute, dataReq);
                if (response.status === 200 || response.status === 202) {
                    // setTotalInDataBase(response.data.content.total);
                    setTotalFounded(response.data.content.total);
                    setTotalPages(response.data.content.pages);
                    setDataSearch(response.data.content.items);
                    setLoading(false)
                    // setPage(currentPage);
                    return
                } else {
                    toast.dismiss();
                    toast.error(response.data.content.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setDataSearch([]);
                    setCurrentPage(1);
                    setLoading(false);
                }
            } catch (error) {
                toast.error(DefaultMessage.ERROR, {
                    position: toast.POSITION.TOP_RIGHT
                })
                setCurrentPage(1);
            }
        }

    }

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: any) => {
        const fileName = event.target.files[0]?.name;
        setSpreadName(fileName);
        setBrowserOrFileName(fileName);
        setFileSelected(event.target.files[0]);
    };

    const [browserOrFileName, setBrowserOrFileName] = useState('Planilha Atualizada');

    // useEffect(() => {
    //     const storedTaskId = localStorage.getItem('excel_task_id');
    //     if (storedTaskId) {
    //         setTaskId(storedTaskId);
    //     } else {
    //         setTaskId(null);
    //     }
    // }, [excelTaskId]);

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { name, value } = e.target;

    // const handleExport = (row: any) => {
    //     alert(`Exportando o arquivo: ${row.exportarResultado}`);
    // };

    // const totalPagesAcc = Math.ceil(tableData.length / rowsPerPage); 

    // const handleExport = (row: any) => {
    //     alert(`Exportando o arquivo: ${row.exportarResultado}`);
    // };

    // const currentData = tableData.slice(
    //     (currentPageAcc - 1) * rowsPerPage,
    //     currentPageAcc * rowsPerPage
    // );

    // const handlePageChangeAcc = (page: number) => {
    //     setCurrentPageAcc(page);
    // };

    // const handleRowsPerPageChangeAcc = (e: SelectChangeEvent<number>) => {
    //     setRowsPerPage(Number(e.target.value));
    //     setCurrentPageAcc(1); 
    // };

    // async function handleZipResponse(data: string ) {
    //     try {
    //         const binaryData = new Uint8Array(
    //             data.split("").map((char) => char.charCodeAt(0))
    //         );
    
    //         // Passo 2: Usar JSZip para processar o arquivo ZIP
    //         const zip = await JSZip.loadAsync(binaryData);
    
    //         // Passo 3: Iterar pelos arquivos no ZIP
    //         zip.forEach((relativePath, file) => {
    //             console.log(`Arquivo encontrado: ${relativePath}`);
    //             file.async("string").then((content) => {
    //                 console.log(`Conteúdo do arquivo (${relativePath}):`, content);
    //             });
    //         });
    //     } catch (error) {
    //         console.error("Erro ao processar o arquivo ZIP:", error);
    //     }
    // }

    // useEffect(() => {
    //     const state = location.state as LocationState;
    //     const response = state?.responseData;
      
    //     if (response) {
    //       setOptions('acc');
    //       console.log('response arquivo', response);
      
    //       const fetchAndProcessExcel = async () => {
    //         try {
    //         //   const responseExcel = await api.get(
    //         //     `/table/pricing/download/monitor/?task_id=${response.content.task_id}`,
    //         //     {
    //         //       responseType: 'blob',
    //         //     }
    //         //   ) as AxiosResponse<Blob>; 
    //         const worksheet_id = '7aaaa708-6529-4163-ba04-4983899f8854'
    //         const responseExcel = (await api.get(`/table/pricing/download/monitor/?worksheet_id=${worksheet_id}`)) as {
    //             data: string;
    //         };
    //           console.log('responseExcel', responseExcel);
    //         //   const base64Data = responseExcel.data; // supondo que `data` é uma string base64
    //         //   const binaryData = Buffer.from(base64Data, 'base64');
    //         //   console.log('binaryData', binaryData)
    //         //   const workbook = XLSX.read(binaryData, {
    //         //     type: 'buffer',
    //         //     raw: true, // Força leitura bruta, sem interpretar strings incorretamente
    //         //   });
    //         //   const sheetName = workbook.SheetNames[0]; // Obtém o nome da primeira planilha
    //         //   const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

    //         //     console.log('sheetData', sheetData); 
                
    //         //     handleZipResponse(responseExcel.data)
    //         } catch (error) {
    //           console.error('Erro ao processar o Excel', error);
    //         }
    //       };
      
    //       fetchAndProcessExcel();
    //     }
    //   }, [location.state]);
      

    return (
        <Core withBlueBar withNavbar path="/pricingTable" label="Clique aqui para cadastrar uma planilha para o precificador">
            <EditionModal paramsEdit={spreadRow} setModal={setModal} state={isOpen} />
            <div
                style={{margin : width < 348 ? "0.4rem 0.2rem -1.3rem" : ""}}
                className={styles.optionsChoiseContainer}
            >
                <button className={styles.searchButton} style={{ background: options === 'sea' ? '#00AEEF' : 'rgba(0, 0, 0, 0.20)' }} onClick={() => setOptions('sea')}>PESQUISAR</button>
                {/* <button className={styles.updateButton} style={{ background: options === 'acc' ? '#00AEEF' : 'rgba(0, 0, 0, 0.20)' }} onClick={() => setOptions('acc')}>ACOMPANHAMENTO</button> */}
                <button className={styles.updateButton} style={{ background: options === 'up' ? '#00AEEF' : 'rgba(0, 0, 0, 0.20)' }} onClick={() => setOptions('up')}>ATUALIZAR</button>
                <button className={styles.statusButton} style={{ background: options === 'sta' ? '#00AEEF' : 'rgba(0, 0, 0, 0.20)' }} onClick={() => setOptions('sta')}>STATUS</button>
            </div>
            <Grid
                container
                direction="column"
                gap={2}
                textAlign="center"
                paddingY={2}
                paddingX={2}
            >
                {
                    options === 'sea' ?
                        <Grid
                            container
                            className={styles.cardsContainer}
                        >
                            <div className={styles.cards}>
                                <TextField
                                    type="text"
                                    placeholder="Informe uma placa ou chassi para pesquisar"
                                    inputRef={textFieldRef}
                                    value={(chassi_or_plate)}
                                    className={styles.input}
                                    sx={{ height: '3rem' }}
                                    onChange={(e) => {
                                        setChassi_or_plate(e.target.value)
                                        if (textFieldRef.current?.value === '') fetchData();
                                    }}
                                />
                                <FormControl sx={{ minWidth: 250, height: '3rem' }}>
                                    <Select
                                        value={somosCode}
                                        onChange={(event) => { setSomosCode(event.target.value) }}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ color: 'gray', fontSize: '.85rem', borderRadius: '2rem', height: '3rem' }}
                                    >
                                        <MenuItem value='all'>
                                            TODOS
                                        </MenuItem>
                                        <MenuItem value='false'>SEM CÓDIGO</MenuItem>
                                        <MenuItem value='true'>COM CÓDIGO</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div
                                className={styles.optionsPricingContainer}
                            >
                                <button className={styles.buttonPricingCheck} onClick={() => { setCurrentPage(1); handleSearch(chassi_or_plate) }}><FaSearch />&nbsp;&nbsp;PESQUISAR</button>
                                {/* <button className={styles.buttonPricingCheck} style={taskId ? { background: 'rgba(0, 0, 0, 0.2)' } : {}} disabled={taskId ? true : false} onClick={() => handleSearch(chassi_or_plate, true)}><FaFileDownload />&nbsp;&nbsp;EXTRAIR XLSX</button> */}
                            </div>
                            <div
                                className={styles.optionsPricingContainer}
                            >
                                <div className={styles.totalFound}><ContentPasteSearchIcon />{totalFounded} de {totalInDataBase} resultados encontrados</div>
                            </div>
                        </Grid>
                        // : options === 'acc' ?
                        //     <Grid
                        //         container
                        //         className={styles.cardsContainer}
                        //         justifyContent="center"
                        //     >
                        //         <Grid
                        //             container
                        //             width="100%"
                        //             display="flex"
                        //             justifyContent="space-between"
                        //             alignItems="end"
                        //             gap="2rem"
                        //         >
                        //             <Grid item container xs={12} sm={9} md={9} spacing={2} display="flex" alignItems="flex-end">

                        //                 <Grid item xs={12} sm={4} md={3}>
                        //                         <Typography variant="body1" textAlign="start">Data inicial</Typography>
                        //                         <TextField
                        //                             type="date"
                        //                             id="initialDate"
                        //                             name="initialDate"
                        //                             value={initialDate}
                        //                             onChange={handleChange}
                        //                             fullWidth
                        //                         />
                        //                 </Grid>

                        //                 <Grid item xs={12} sm={4} md={3}>
                        //                         <Typography variant="body1" textAlign="start">Data final</Typography>
                        //                         <TextField
                        //                             type="date"
                        //                             id="finalDate"
                        //                             name="finalDate"
                        //                             value={finalDate}
                        //                             onChange={handleChange}
                        //                             fullWidth
                        //                         />
                        //                 </Grid>

                        //                 <Grid item xs={12} sm={3} md={2}>
                        //                         <Button
                        //                             className="btn-inverse btn-inverse-2"
                        //                             variant="contained"
                        //                             size="large"
                        //                             color="secondary"
                        //                             type="submit"
                        //                             fullWidth
                        //                             style={{
                        //                                 borderRadius: '50rem',
                        //                                 height: '3rem',
                        //                                 color: 'white',
                        //                                 background: '#00B9F2'
                        //                             }}
                        //                         >
                        //                             Filtrar
                        //                         </Button>
                        //                 </Grid>

                        //             </Grid>
                                    
                        //             <Grid
                        //                 item
                        //                 xs={12}
                        //                 sm={2}
                        //                 md={2}
                        //                 display="flex"
                        //                 justifyContent="flex-end"
                        //                 alignItems="center"
                        //             >

                        //                     <FormControl>
                        //                         <Select
                        //                             labelId="rows-per-page-label"
                        //                             id="rowsPerPage"
                        //                             value={rowsPerPage}
                        //                             onChange={handleRowsPerPageChangeAcc}
                        //                             displayEmpty
                        //                             fullWidth
                        //                             style={{
                        //                                 borderRadius: '50px',
                        //                                 padding: '1px', 
                        //                                 backgroundColor: '#f5f5f5', 
                        //                                 width: '5rem',
                        //                                 alignItems: "center",
                        //                                 textAlign: "center",
                        //                                 justifyContent: "center",
                        //                                 display: "flex",
                        //                             }}
                        //                             // IconComponent={() => <MoreHorizIcon style={{ color: '#00B9F2' }} />} // Substitui o ícone padrão
                        //                         >
                        //                             <MenuItem value={10}>10</MenuItem>
                        //                             <MenuItem value={20}>20</MenuItem>
                        //                             <MenuItem value={30}>30</MenuItem>
                        //                             <MenuItem value={50}>50</MenuItem>
                        //                             <MenuItem value={100}>100</MenuItem>
                        //                         </Select>
                        //                 </FormControl>
                        //             </Grid>
                        //         </Grid>

                        //         <div
                        //             className={` rounded-2xl overflow-x-scroll overflow-y-hidden border border-gray-300 my-4 shadow-md ${styles.table}`}
                        //         >
                        //             <table className="w-full border-collapse text-center">
                        //                 <thead className="bg-[#00AEEF] text-white font-bold">
                        //                     <tr>
                        //                         <th className="p-[.8rem]">Arquivo</th>
                        //                         <th className="p-[.8rem]">Criado por</th>
                        //                         <th className="p-[.8rem]">Criado em</th>
                        //                         <th className="p-[.8rem]">Atualizado em</th>
                        //                         <th className="p-[.8rem]">Status</th>
                        //                         <th className="p-[.8rem]">Total de Linhas</th>
                        //                         <th className="p-[.8rem]">Processadas com sucesso</th>
                        //                         <th className="p-[.8rem]">Processadas com erro</th>
                        //                         <th className="p-[.8rem]">Exportar</th>
                        //                     </tr>
                        //                 </thead>
                        //                 <tbody>
                        //                     {currentData.map((row, index) => (
                        //                         <tr
                        //                             key={index}
                        //                             className="border-b border-gray-300 hover:bg-gray-100"
                        //                         >
                        //                             <td className="p-[.8rem]">{row.nomeArquivo}</td>
                        //                             <td className="p-[.8rem]">{row.criadoPor}</td>
                        //                             <td className="p-[.8rem]">{row.criadoEm}</td>
                        //                             <td className="p-[.8rem]">{row.atualizadoEm}</td>
                        //                             <td className="p-[.8rem]">{row.status}</td>
                        //                             <td className="p-[.8rem]">{row.totalLinhas}</td>
                        //                             <td className="p-[.8rem]">{row.processadasComSucesso}</td>
                        //                             <td className="p-[.8rem]">{row.processadasComErro}</td>
                        //                             <td className="p-[.8rem]">
                        //                                 <button
                        //                                     className="text-[#00AEEF] hover:text-cyan-800 focus:outline-none"
                        //                                     onClick={() => handleExport(row)}
                        //                                 >
                        //                                     <FaArrowAltCircleDown size={20} />
                        //                                 </button>
                        //                             </td>
                        //                         </tr>
                        //                     ))}
                        //                 </tbody>
                        //             </table>
                        //         </div>

                        //         <Pagination currentPage={currentPageAcc} totalPages={totalPagesAcc} onPageChange={handlePageChangeAcc} />

                        //     </Grid>
                            : options === 'up' ?
                                <Grid
                                    container
                                    direction="row"
                                    gap={2}
                                    textAlign="center"
                                    paddingY={2}
                                    paddingX={2}
                                    className={styles.cardsContainer}
                                >
                                    <div className={styles.updatedSpreadsheetButton}>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        <button
                                            type="button"
                                            className={styles.uploaderButton}
                                            onClick={handleButtonClick}
                                        >
                                            {browserOrFileName}
                                        </button>
                                    </div>

                                    <button className={styles.buttonPricingCheck} onClick={uploadUpdatedTable}>ATUALIZAR</button>
                                </Grid>
                                :
                                <Grid
                                    container
                                    direction="row"
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    className={styles.cardsStatusContainer}
                                >
                                    <div className={styles.spreadsheetUpdate}>
                                        <h5>Planilha: {spreadName ? spreadName : 'Nenhuma em análise'}</h5>
                                        <p className={styles.spreadsheetStatus} >Status: {spreadStatus ? spreadStatus : 'Nenhum'}</p>
                                    </div>
                                </Grid>
                }
                {   
                    options === 'acc' ? <></> :
                    loading
                        ?
                        <Grid
                            container
                            direction="row"
                            alignItems={'center'}
                            justifyContent={'center'}
                            height={window.innerWidth < 900 ? "40vh" : '60vh'}
                        >
                            <CircularProgress size={60} />
                        </Grid>
                        :
                        dataSearch?.map(

                            (item: any, index: number) =>

                                <div key={`fiv-list-item-${index}`} style={{ marginTop: 20 }}>
                                    <ListItemButton onClick={() => { setIndexSelected(index) }}>
                                        <ListItemIcon>
                                            <Avatar>
                                                {
                                                    <FaTrailer />
                                                }
                                            </Avatar>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Fragment>
                                                    <Typography
                                                        variant="body1"
                                                        color="text.primary"
                                                    >
                                                        <strong>CHASSI: </strong>{item.chassi}
                                                    </Typography>
                                                </Fragment>
                                            }
                                            secondary={
                                                <Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body1"
                                                        color="text.primary"
                                                    >
                                                        <strong>PLACA: </strong>{item.plate}
                                                    </Typography>
                                                </Fragment>
                                            }
                                        />
                                        {index === indexSelected ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={index === indexSelected} timeout="auto" unmountOnExit sx={{ alignItems: 'end' }}>
                                        <List component="div" disablePadding>
                                            <ListItem sx={{ pl: 4, alignItems: 'end' }}>
                                                <ListItemText
                                                    primary={
                                                        <Fragment>
                                                            <Typography
                                                                variant="body1"
                                                                color="text.primary"
                                                            >
                                                                <strong>PRODUTO: </strong>{item.product}
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                color="text.primary"
                                                            >
                                                                <strong>MARCA: </strong>{item.fabricator}
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                color="text.primary"
                                                            >
                                                                <strong>MODELO: </strong>{item.brand_model}
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                color="text.primary"
                                                            >
                                                                <strong>ANO DE FABRICAÇÂO: </strong>{item.year_fabrication}
                                                            </Typography>
                                                        </Fragment>
                                                    }
                                                />
                                                <Button variant="contained" sx={{ borderRadius: '30px', color: 'white', marginBottom: '.5rem' }} onClick={() => { setSpreadRow([item]); setModal(true) }}>VISUALIZAR</Button>
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                        )
                }
                {
                    options === 'acc' ? <></> 
                    :
                    !loading ?
                        Array.isArray(dataSearch) && dataSearch.length >= 1 && (
                            <div style={{ marginTop: '5rem' }}>
                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                            </div>
                        ) : <></>
                }
            </Grid>
        </Core>
    )
}